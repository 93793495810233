import { DeleteOutlined, EditOutlined, FileSearchOutlined, PlusOutlined, PaperClipOutlined, UploadOutlined, CloudUploadOutlined, CheckOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Layout, Card, Input, Button, Modal, Form, Popconfirm, Row, Col, message, Upload, Badge, Tooltip } from 'antd';
import axios from 'axios';
import React, { Component } from 'react';
import Configuration from '../Configuration.js';
import { Navigate } from 'react-router-dom';
import TopMenu from '../Components/TopMenu';
import SideMenu from '../Components/SideMenu';

const { Meta } = Card;
const { Search } = Input;
const { Header, Content, Sider } = Layout;
const items = Configuration.sideMenu();

class Organos extends Component {
  constructor(props) {
    super(props);

    // Auth
    const jwt = localStorage.getItem('token');
    this.headers = {authorization: `Bearer: ${jwt}`};

    // State
    this.state = {searching: false, redirect: false, redirectUrl: '', showUpload: false, records: [], loading: false, openModal: false, modalTitle: '', selectedRecord: {}, uploading: false, fileList: [], canetFields: false};
  }
  
  componentDidMount() {
    this.getRecords("");
  }

  getRecords(words) {
    const query = `{listAcreditors(search: "${words}"){id,shortName,longName,status,logo,conaetEducativeModel,conaetAgeEducationalProgram,conaetRelevanceStudy,conaetGraduateGenerations,conaetStatisticsData}}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al procesar tus credenciales');
      }
      
      if('errors' in resp.data) {
        // console.log(resp.data);
        return message.warning('Error al obtener los registros');
      }

      const response = resp.data.data.listAcreditors;
      //console.log(response);
      this.setState({records: response, searching: false});
    });
  }

  onSearch(words) {
    this.setState({searching: true});
    // Search record
    this.getRecords(words);
  }

  handleCreateModal() {
    this.setState({openModal: true, selectedRecord: {}, canetFields: false, fileList: [], modalTitle: 'Nuevo órgano acreditador'});
  }

  handleSubmitModal() {
    this.setState({loading: true});

    // Update or create
    if ('id' in this.state.selectedRecord) {
      this.updateRecord();
    } else {
      this.createRecord();
    }
  }

  createRecord() {
    const record = this.state.selectedRecord;
    let query = `mutation{createAcreditor(shortName: "${record.shortName}", longName: "${record.longName}"){id}}`;

    // Canet fields
    // if (record.conaetEducativeModel) {
    //   query = `mutation{createAcreditor(shortName: "${record.shortName}", longName: "${record.longName}", conaetEducativeModel: "${record.conaetEducativeModel}", conaetAgeEducationalProgram: "${record.conaetAgeEducationalProgram}", conaetRelevanceStudy: "${record.conaetRelevanceStudy}", conaetGraduateGenerations: "${record.conaetGraduateGenerations}", conaetStatisticsData: "${record.conaetStatisticsData}"){id}}`;
    // }

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al procesar tus credenciales');
      }
      
      if('errors' in resp.data) {
        console.log(resp.data);
        return message.warning('Error al crear el registro. Llena todos los campos obligatorios.');
      }

      const response = resp.data.data.createAcreditor;
      //console.log(response);
      this.setState({showUpload: true, selectedRecord: response});
    });
  }

  handleUpload() {
    let formData = new FormData();
    this.state.fileList.forEach((file) => {
      formData.append('file', file);
    });
    this.setState({uploading: true});    
    // Upload logo
    const query = `mutation{uploadAcreditorLogo(id: ${this.state.selectedRecord.id}){id}}`;
    
    fetch(`${Configuration.apiServer}/api/v1?query=${query}`, {
      method: 'POST',
      body: formData,
      headers: this.headers
    })
    .then((res) => res.json())
    .then(() => {
      message.success('El logotipo se ha subido correctamente.');
    })
    .catch(() => {
      message.error('Hubo un error al subir el logotipo.');
    })
    .finally(() => {
      this.setState({showUpload: false, selectedRecord: {}, openModal: false, modalTitle: ''});
      this.getRecords('');
    });
  }

  openLogoModal(record) {
    this.setState({showUpload: true, selectedRecord: record, openModal: true, modalTitle: 'Subir logotipo'});
  }

  beforeUpload(file) {
    let files = [...this.state.fileList];
    files.push(file);
    this.setState({fileList: files});
    return false;
  }
  
  removeUpload(file) {
    const index = this.state.fileList.indexOf(file);
    let newFileList = this.state.fileList.slice();
    newFileList.splice(index, 1);
    this.setState({fileList: newFileList});
  }

  cancelUpload() {
    this.setState({openModal: false, modalTitle: '', selectedRecord: {}, loading: false, showUpload: false});
    this.getRecords("");
  }

  handleEditModal(record) {
    if (record.conaetEducativeModel) {
      this.setState({openModal: true, modalTitle: 'Editar órgano acreditador', selectedRecord: record, canetFields: true});
    } else {
      this.setState({openModal: true, modalTitle: 'Editar órgano acreditador', selectedRecord: record});
    }
  }

  updateRecord() {
    const selected = this.state.selectedRecord;
    let query = `mutation{updateAcreditor(id: ${selected.id}, shortName: "${selected.shortName}", longName: "${selected.longName}"){id}}`;

    // Canet fields
    // if (record.conaetEducativeModel) {
    //   query = `mutation{updateAcreditor(id: ${selected.id}, shortName: "${record.shortName}", longName: "${record.longName}", conaetEducativeModel: "${record.conaetEducativeModel}", conaetAgeEducationalProgram: "${record.conaetAgeEducationalProgram}", conaetRelevanceStudy: "${record.conaetRelevanceStudy}", conaetGraduateGenerations: "${record.conaetGraduateGenerations}", conaetStatisticsData: "${record.conaetStatisticsData}"){id}}`;
    // }

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al procesar tus credenciales');
      }
      
      if('errors' in resp.data) {
        return message.warning('Error al actualizar el registro. Llena todos los campos obligatorios.');
      }

      const response = resp.data.data.updateAcreditor;
      //console.log(response);
      this.setState({showUpload: true, selectedRecord: response});
    });
  }

  deleteRecord(record) {
    const query = `mutation{deleteAcreditor(id: ${record.id}){id}}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al procesar tus credenciales');
      }
      
      if('errors' in resp.data) {
        console.log(resp.data);
        return message.warning('Error al actualizar el registro. Llena todos los campos obligatorios.');
      }

      this.getRecords('');
    });
  }

  handleStatus(record) {
    const query = `mutation{statusAcreditor(id: ${record.id}){id}}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al procesar tus credenciales');
      }
      
      if('errors' in resp.data) {
        console.log(resp.data);
        return message.warning('Error al actualizar el registro. Llena todos los campos obligatorios.');
      }

      this.getRecords('');
    });
  }

  handleCancelModal() {
    this.setState({openModal: false, modalTitle: '', selectedRecord: {}});
  }

  openDetails(record) {
    this.setState({redirect: true, redirectUrl: `/organo-acreditador/${record.id}`});
  }


  // Render UI
  render() {
    if(this.state.redirect) {
      return (
        <Navigate to={this.state.redirectUrl} />
      );
    }

    return (
      <Layout style={{background: '#fff'}}>
        {/* Modal for new and edit */}
        <Modal
          open={this.state.openModal}
          title={this.state.modalTitle}
          onOk={() => {}}
          onCancel={this.handleCancelModal.bind(this)}
          footer={false}
          width='70%'
        >
          {this.state.showUpload ?
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', flexWrap: 'wrap', padding: '30px 0px'}}>
              <Upload onRemove={(file) => { this.removeUpload(file) }} beforeUpload={(file) => { this.beforeUpload(file) }} fileList={this.state.fileList}>
                <Button type="primary" icon={<UploadOutlined />}>Subir logotipo</Button>
              </Upload>

              <div style={{borderBottom: '1px dotted #ccc', width: '100%', margin: '20px 0px 10px 0px'}}></div>

              <div style={{marginTop: '10px'}}>
                <Button onClick={this.cancelUpload.bind(this)} style={{marginRight: 5}}>Cancelar</Button>
                <Button type="primary" onClick={this.handleUpload.bind(this)} disabled={this.state.fileList.length === 0} loading={this.state.uploading} style={{marginTop: 15}}>
                  {this.state.uploading ? 'Subiendo...' : 'Subir'}
                </Button>
              </div>
            </div>
          :
            <div>
              <div className="ant-form-item">
                <div className="ant-row ant-form-item-row">
                  <div className="ant-col ant-col-8 ant-form-item-label">
                    <label className="ant-form-item-required" title="Nombre">Nombre</label>
                  </div>
                  <div className="ant-col ant-col-16 ant-form-item-control">
                    <Input value={this.state.selectedRecord?.shortName} onChange={(val) => this.setState({selectedRecord: {...this.state.selectedRecord, shortName: val.target.value}})} />
                  </div>
                </div>
              </div>

              <div className="ant-form-item">
                <div className="ant-row ant-form-item-row">
                  <div className="ant-col ant-col-8 ant-form-item-label">
                    <label className="ant-form-item-required" title="Nombre">Nombre completo oficial</label>
                  </div>
                  <div className="ant-col ant-col-16 ant-form-item-control">
                    <Input value={this.state.selectedRecord?.longName} onChange={(val) => this.setState({selectedRecord: {...this.state.selectedRecord, longName: val.target.value}})} />
                  </div>
                </div>
              </div>

              <div className='ant-row ant-form-item-row' style={{justifyContent: 'center', marginTop: '10px'}}>
                <Button onClick={this.handleCancelModal.bind(this)} style={{marginRight: 5}}>Cancelar</Button>
                <Button type="primary" onClick={this.handleSubmitModal.bind(this)}>Guardar</Button>
              </div>
            </div>
          }
        </Modal>

        {/* Top menu */}
        <TopMenu />

        {/* Content with two columns */}
        <Layout className="" style={{background: '#fff'}}>
          <SideMenu />

          <Content style={{margin: '24px 16px 0'}}>
            <div style={{padding: 24, textAlign: 'center'}}>

              {/* Header seccions */}
              <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
                <h3 style={{marginBottom: 0}}>Órganos Acreditadores</h3>

                <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'flex-end', width: '100%'}}>
                  <Search placeholder="Busca por nombre..." onSearch={this.onSearch.bind(this)} size="large" loading={this.state.searching} style={{width: '30%'}} />
                  <Button icon={<PlusOutlined />} size="large" onClick={this.handleCreateModal.bind(this)} style={{backgroundColor: '#1B3C6A', color: '#fff'}}>Órgano acreditador</Button>
                </div>
              </div>

              {/* Content */}
              <div className="container-between" style={{marginTop: 30}}>
                <Row justify="start" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  {this.state.records.map((item, key) => {
                    return (
                      <Col key={`org-${key}`} style={{border: '1px solid #ccc', background: '#fff', borderRadius: 5, marginRight: 20, paddingLeft: 0, paddingRight: 0, marginBottom: 10, width: '30%'}}>
                        <Badge.Ribbon text={item.status ? 'Activado' : 'Desactivado'}>
                          <div style={{height: 150, display: 'flex', alignItems: 'center', cursor: 'pointer'}} onClick={this.openLogoModal.bind(this, item)}>
                            <img alt='' src={`${Configuration.apiServer}${item.logo}`} style={{maxHeight: '100px', margin: '40px auto 10px auto'}}/> 
                            <div style={{position: 'absolute', borderRadius: 10, background: '#ddd', marginTop: 110, marginLeft: 10, width: 22, height: 22}}>
                              <CloudUploadOutlined style={{}} />
                            </div>
                          </div>

                          <div style={{borderBottom: '1px solid #ccc', marginTop: 10, marginBottom: 10}}></div>
                          
                          <div style={{paddingTop: 15}}>
                            <h2 style={{marginBottom: 0}}>{item.shortName}</h2>
                            <p style={{marginBottom: 20, color: '#777'}}>{item.longName && item.longName != "undefined" ? item.longName : ""}</p>
                              
                            <Button type="link" block onClick={this.openDetails.bind(this, item)} style={{backgroundColor: '#f1f1f1'}}>
                              <PaperClipOutlined /> <span>Instrumento de evaluación</span>
                            </Button>
                          </div>


                          <div style={{width: '100%', display: 'flex', justifyContent: 'space-around', paddingBottom: 10, paddingTop: 10}}>
                            <Tooltip placement="top" title={'Detalles'}>
                              <Button shape="circle" type="primary" ghost style={{marginRight: 5}} onClick={this.openDetails.bind(this, item)}><FileSearchOutlined /></Button>
                            </Tooltip>

                            <Tooltip placement="top" title={'Editar'}>
                              <Button shape="circle" type="primary" ghost style={{marginRight: 5}} onClick={this.handleEditModal.bind(this, item)}><EditOutlined /></Button>
                            </Tooltip>

                            <Tooltip placement="bottom" title={'Activar / Desactivar'}>
                              {item.status ?
                                <Button shape="circle" type="primary" ghost style={{marginRight: 5}}>
                                  <Popconfirm title="¿Deseas cambiar el status?" cancelText='Cancelar' onConfirm={this.handleStatus.bind(this, item)}>
                                    <CheckOutlined />
                                  </Popconfirm>
                                </Button>
                              : 
                                <Button shape="circle" type="primary" ghost style={{marginRight: 5}}>
                                  <Popconfirm title="¿Deseas cambiar el status?" cancelText='Cancelar' onConfirm={this.handleStatus.bind(this, item)}>
                                    <CloseCircleOutlined />
                                  </Popconfirm>
                                </Button>
                              }
                            </Tooltip>

                            <Tooltip placement="bottom" title={'Borrar'}>
                              <Button shape="circle" type="primary" ghost style={{marginRight: 5}}>
                                <Popconfirm title="¿Deseas borrar el registro?" cancelText='Cancelar' onConfirm={this.deleteRecord.bind(this, item)}>
                                  <DeleteOutlined />
                                </Popconfirm>
                              </Button>
                            </Tooltip>
                          </div>
                        </Badge.Ribbon>
                      </Col>
                    );
                  })}
                </Row>
              </div>

            </div>
          </Content>


        </Layout>
      </Layout>
    );
  }
}

export default Organos;
