import { FileSearchOutlined, DeleteOutlined } from '@ant-design/icons';
import { Layout, Card, Input, Button, Progress, List, Tooltip, Col, Popconfirm, Row, Select, message } from 'antd';
import React, { Component } from 'react';
import Configuration from '../Configuration.js';
import { Navigate } from 'react-router-dom';
import TopMenu from '../Components/TopMenu';
import SideMenu from '../Components/SideMenu';
import axios from 'axios';

const { Meta } = Card;
const { Search } = Input;
const { Header, Content, Sider } = Layout;
const items = Configuration.sideMenu();

class Reportes extends Component {
  constructor(props) {
    super(props);

    // Auth
    const jwt = localStorage.getItem('token');
    this.headers = {authorization: `Bearer: ${jwt}`};

    this.state = {searching: false, redirect: false, redirectUrl: '', allRecords: [], records: [], totalPending: 0, totalErrors: 0, totalAuthorized: 0, totalIncomplete: 0, total: 0, loading: false, openModal: false, modalTitle: '', selectedRecord: {}, acreditors: [{id: "all", shortName: "Todos los órganos"}], selectedAcreditor: "all", carrers: [{id: "all", carrer: {name: "Todas las carreras"}}], selectedCarrer: "all", categories: [{id: "all", criteriaCategory: {name: "Todas las categorias"}}], selectedCategory: "all", criterias: [{id: "all", criteria: {id: "all", name: "Todos los criterios"}}], selectedCriteria: "all", selectedStatus: "all"};
  }

  componentDidMount() {
    this.getReport("all", "all", "all", "all", "all");
    this.getAcreditors();
  }

  getReport(acreditor, carrer, category, criteria, status) {
    const query = `{getReport(acreditor: "${acreditor}" carrer: "${carrer}", category: "${category}", criteria: "${criteria}", status: "${status}"){ pending, error, authorized, incomplete, total, indicators{ id, folio, question, status, maxVal, notes, reason, user{id,name}, area{id,name}, criteria{id, name, number}, acreditor{id, shortName}, carrer{id, name}, criteriaCategory{id,name, folio}, users{id, name}, areas{id, name} } }}`;

    console.log(query);
    
    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al procesar tus credenciales');
      }
      
      if('errors' in resp.data) {
        return message.warning('Error al consultar el indicador.');
      }

      console.log(resp.data.data.getReport?.indicators.length);
      this.setState({ totalPending: resp.data.data.getReport?.pending, totalErrors: resp.data.data.getReport?.error, totalAuthorized: resp.data.data.getReport?.authorized, totalIncomplete: resp.data.data.getReport?.incomplete, total: resp.data.data.getReport?.total, records: resp.data.data.getReport?.indicators });
    });
  }

  getAcreditors() {
    const query = `{listAcreditors(search: ""){id, shortName}}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al procesar tus credenciales');
      }
      
      if('errors' in resp.data) {
        // console.log(resp.data);
        return message.warning('Error al obtener los organos');
      }

      const response = resp.data.data.listAcreditors;
      response.push({id: "all", shortName: "Todos los órganos"});
      //console.log(response);
      this.setState({acreditors: response});
    });
  }

  filterAcreditor(acre) {
    if (acre === "all") {
      this.setState({ selectedAcreditor: acre, carrers: [{id: "all", carrer: {id: "all", name: "Todas las carreras"}}], selectedCarrer: "all", categories: [{id: "all", criteriaCategory: {name: "Todas las categorias"}}], selectedCategory: "all", criterias: [{id: "all", criteria: {id: "all", name: "Todos los criterios"}}], selectedCriteria: "all" });
    } else {
      const query = `{listCarrersFromAcreditor(id: ${acre}){id,criteria,carrer{id,name}}}`;

      axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
        if(resp.status !== 200) {
          return message.warning('Hubo un error al procesar tus credenciales');
        }
        
        if('errors' in resp.data) {
          // console.log(resp.data);
          return message.warning('Error al obtener los organos');
        }

        const response = resp.data.data.listCarrersFromAcreditor;
        response.push({id: "all", carrer: {name: "Todas las carreras"}});
        //console.log(response);
        this.setState({selectedAcreditor: acre, carrers: response});
      });
    }

    // Update list
    this.getReport(acre, this.state.selectedCarrer, this.state.selectedCategory, this.state.selectedCriteria, this.state.selectedStatus);
  }

  filterCarrer(car) {
    if (car === "all") {
      this.setState({ selectedCarrer: car, categories: [{id: "all", criteriaCategory: {name: "Todas las categorias"}}], selectedCategory: "all", criterias: [{id: "all", criteria: {id: "all", name: "Todos los criterios"}}], selectedCriteria: "all" });
    } else {
      const query = `{listCriteriaCategoriesFromCarrer(id: ${car}){id,criteria,criteriaCategory{id,name,folio}}}`;

      axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
        if(resp.status !== 200) {
          return message.warning('Hubo un error al procesar tus credenciales');
        }
        
        if('errors' in resp.data) {
          // console.log(resp.data);
          return message.warning('Error al obtener los organos');
        }

        const response = resp.data.data.listCriteriaCategoriesFromCarrer;
        response.push({id: "all", criteriaCategory: {name: "Todas las categorias"}});
        //console.log(response);
        this.setState({selectedCarrer: car, categories: response});
      });
    }

    // Update list
    this.getReport(this.state.selectedAcreditor, car, this.state.selectedCategory, this.state.selectedCriteria, this.state.selectedStatus);
  }

  filterCategory(cat) {
    if (cat === "all") {
      this.setState({ selectedCategory: cat, criterias: [{id: "all", criteria: {id: "all", name: "Todos los criterios"}}], selectedCriteria: "all" });
    } else {
      const query = `{listCriteriaFromCategory(id: ${cat}){id,indicators,criteria{id,name,number,description}}}`;

      axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
        if(resp.status !== 200) {
          return message.warning('Hubo un error al procesar tus credenciales');
        }
        
        if('errors' in resp.data) {
          // console.log(resp.data);
          return message.warning('Error al obtener los organos');
        }

        const response = resp.data.data.listCriteriaFromCategory;
        response.push({id: "all", criteria: {id: "all", name: "Todos los criterios"}});
        //console.log(response);
        this.setState({selectedCategory: cat, criterias: response});
      });
    }

    // Update list
    this.getReport(this.state.selectedAcreditor, this.state.selectedCarrer, cat, this.state.selectedCriteria, this.state.selectedStatus);
  }

  filterCriteria(cri) {
    this.setState({selectedCriteria: cri});
    // Update list
    this.getReport(this.state.selectedAcreditor, this.state.selectedCarrer, this.state.selectedCategory, cri, this.state.selectedStatus);
  }

  filterStatus(status) {
    this.setState({selectedStatus: status});
    // Update list
    this.getReport(this.state.selectedAcreditor, this.state.selectedCarrer, this.state.selectedCategory, this.state.selectedCriteria, status);
  }

  onClickMainMenu(item) {
    switch(item.key) {
      case '0':
        this.setState({redirect: true, redirectUrl: '/notificaciones'});
        break;
      case '1':
        this.setState({redirect: true, redirectUrl: '/login'});
        break;
    }
  }

  onClickSideMenu(item) {
    if (item.key != "2") {
      const route = Configuration.getRoute(item);
      this.setState({redirect: true, redirectUrl: route});
    }
  }

  handleCreateModal() {
    this.setState({openModal: true, modalTitle: 'Nuevo área'});
  }

  handleSubmitModal(data) {
    this.setState({loading: true});

    // Update or create
    if ('name' in this.state.selectedRecord) {
      this.updateRecord(data);
    } else {
      this.createRecord(data);
    }
  }

  createRecord(record) {
    // Add new record
    const newRecords = this.state.records;
    record.id = newRecords.length + 1;
    record.users = 0;
    record.indicators = 0;
    newRecords.push(record);
    // Close modal
    this.setState({openModal: false, modalTitle: '', loading: false, records: newRecords});
  }

  handleEditModal(record) {
    this.setState({openModal: true, modalTitle: 'Editar área', selectedRecord: record});
  }

  updateRecord(record) {
    const newRecords = this.state.records;
    const selected = this.state.selectedRecord;
    // Find record
    const list = newRecords.map(el => { 
      if (el.id == selected.id) {
        let newRecord = {...el};
        newRecord.name = record.name;
        return newRecord;
      } else {
        return el;
      }
    });

    // Close modal
    this.setState({openModal: false, modalTitle: '', loading: false, records: list});
  }

  deleteRecord(record) {
    const filter = this.state.records.findIndex((el, index) => {
      if (record.id == el.id) {
        return el;
      }
    });
    
    const newRecords = this.state.records;
    delete newRecords[filter];
    this.setState({records: newRecords});
  }

  handleCancelModal() {
    this.setState({openModal: false, modalTitle: ''});
  }

  openDetails(record) {
    this.setState({redirect: true, redirectUrl: `/area/${record.id}`});
  }

  statusColor(status) {
    switch(status) {
      case 'Pendiente':
        return '#C7AD08';
      case 'Por revisar':
        return '#C7AD08';;
      case 'En revisión':
        return '#FA8C16';
      case 'Con errores':
        return '#F5212D';
      case 'Autorizado':
        return '#52C51A';
    }
  }



  // Render UI
  render() {
    if(this.state.redirect) {
      return (
        <Navigate to={this.state.redirectUrl} />
      );
    }

    return (
      <Layout>
        {/* Top menu */}
        <TopMenu />

        <Layout className="site-layout">
          <SideMenu />

          <Content style={{margin: '24px 16px 0', background: '#fff'}}>
            <div className="site-layout-background" style={{padding: 24, textAlign: 'center', height: '85vh', overflowY: 'scroll'}}>

              {/* Header seccions */}
              <div className='container-between' style={{ marginBottom: '20px' }}>
                <h3>Reporte de indicadores</h3>
              </div>

              {/* Content */}
              <div className="container-around" style={{borderBottom: '1px dotted #ddd', paddingBottom: 30, marginBottom: 30}}>
                <div className="container-center" style={{border: '1px solid #324091', backgroundColor: '#324091', borderRadius: 15, padding: 10, width: '22%', marginBottom: 5}}>
                  <h4 style={{marginBottom: 0, width: 150, textAlign: 'center', color: '#fff'}}>Por revisar <b>({this.state.totalPending})</b></h4>
                </div>
                <div className="container-center" style={{border: '1px solid #324091', backgroundColor: '#324091', borderRadius: 15, padding: 10, width: '22%', marginBottom: 5}}>
                  <h4 style={{marginBottom: 0, width: 150, textAlign: 'center', color: '#fff'}}>Errores <b>({this.state.totalErrors})</b></h4>
                </div>
                <div className="container-center" style={{border: '1px solid #324091', backgroundColor: '#324091', borderRadius: 15, padding: 10, width: '22%', marginBottom: 5}}>
                  <h4 style={{marginBottom: 0, width: 150, textAlign: 'center', color: '#fff'}}>Incompleto <b>({this.state.totalIncomplete})</b></h4>
                </div>
                <div className="container-center" style={{border: '1px solid #324091', backgroundColor: '#324091', borderRadius: 15, padding: 10, width: '22%', marginBottom: 5}}>
                  <h4 style={{marginBottom: 0, width: 150, textAlign: 'center', color: '#fff'}}>Autorizados<b> ({this.state.totalAuthorized})</b></h4>
                </div>
              </div>

              <div style={{border: '1px solid #ddd', borderRadius: 15, padding: 20, width: '100%', marginBottom: 5}}>

                <div className="container-between" style={{ width: '100%' }}>
                  <Select
                    defaultValue={this.state.selectedAcreditor}
                    style={{ width: '19%' }}
                    onChange={this.filterAcreditor.bind(this)}
                    options={this.state.acreditors.map((acre) => {
                      return {value: acre.id, label: acre.shortName};
                    })}
                  />
                  
                  <Select
                    defaultValue={this.state.selectedCarrer}
                    style={{ width: '19%' }}
                    onChange={this.filterCarrer.bind(this)}
                    options={this.state.carrers.map((ca) => {
                      return {value: ca.id, label: ca.carrer?.name};
                    })}
                  />

                  <Select
                    defaultValue={this.state.selectedCategory}
                    style={{ width: '19%' }}
                    onChange={this.filterCategory.bind(this)}
                    options={this.state.categories.map((ca) => {
                      return {value: ca.id, label: ca.criteriaCategory?.name};
                    })}
                  />

                  <Select
                    defaultValue={this.state.selectedCriteria}
                    style={{ width: '19%' }}
                    onChange={this.filterCriteria.bind(this)}
                    options={this.state.criterias.map((cri) => {
                      return {value: cri.criteria?.id, label: cri.criteria?.name};
                    })}
                  />

                  <Select
                    defaultValue={this.state.selectedStatus}
                    style={{ width: '19%' }}
                    onChange={this.filterStatus.bind(this)}
                    options={[
                      {value: 'all', label: 'Todos los status'},
                      {value: 'Por revisar', label: 'Por revisar'},
                      {value: 'Incompleto', label: 'Incompleto'},
                      {value: 'Con errores', label: 'Con errores'},
                      {value: 'Autorizado', label: 'Autorizado'},
                    ]}
                  />
                  
                </div>

                {/* <div className="container-center" style={{ width: '100%', marginTop: 5 }}>
                  <Button type="primary" style={{marginRight: 10}}><a href="/limpiar">Filtrar</a></Button>
                  <Button><a href="/limpiar">Exportar</a></Button>
                </div> */}
              </div>

              <List
                className="demo-loadmore-list"
                loading={false}
                itemLayout="horizontal"
                loadMore={null}
                dataSource={this.state.records} 
                style={{width: '100%', background: '#fff', padding: 10, textAlign: 'left'}}
                renderItem={item => (
                  <List.Item key={`car-${item.id}`} style={{width: '100%'}}>
                      <Row style={{width: '100%'}}>
                        <Col flex="1 1 200px">
                          <List.Item.Meta
                            title={
                              <div style={{textAlign: 'left', paddingRight: '40px'}}>
                                <div style={{backgroundColor: '#ddd', padding: '10px 5px', borderRadius: 5}}>
                                  <span style={{marginRight: '6px', fontWeight: 'bold'}}>{item.folio}</span>
                                  <span style={{color: '#1B3C6A', paddingRight: 30}}>
                                    <b>{item.question}</b>
                                  </span>
                                </div>

                                <div style={{backgroundColor: '#f1f1f1', padding: '10px 5px', borderRadius: 5}}>
                                  {/*item.user ? 
                                    <p style={{marginBottom: 10, fontWeight: 'bold'}}>
                                      <span style={{marginRight: 5}}>Creado por</span>
                                      <span>{item.user.name}</span>
                                    </p>
                                  : null*/}

                                  <div style={{marginBottom: 10, display: 'flex', alignItems: 'center', justifyContent: 'space-start'}}>
                                    <span style={{marginRight: 5}}>Asignado a</span>
                                    {item.users.map((u) => {
                                      return (<div key={`ind-user-${u.id}`} style={{border: '1px solid #ccc', borderRadius: 5, marginRight: 5, padding: 5}}>
                                        <span style={{marginRight: 10}}>{u.name} </span>
                                      </div>);
                                    })}
                                  </div>

                                  <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-start'}}>
                                    <span style={{marginRight: 5}}>Áreas</span>
                                    {item.areas.map((u, index) => {
                                      return (<div key={`ind-area-${index}-${u.id}`} style={{border: '1px solid #ccc', borderRadius: 5, marginRight: 5, padding: 5}}>
                                        <span style={{marginRight: 10}}>{u.name} </span>
                                      </div>);
                                    })}
                                  </div>
                                </div>

                                {item.notes ?
                                  <div style={{marginTop: 10, marginBottom: 50}} dangerouslySetInnerHTML={{ __html: item.notes }} />
                                : null}
                              </div>
                            }
                          />
                        </Col>

                        <Col span={2}>
                          {/* <div style={{padding: '5px 10px', border: '1px solid #777', borderRadius: 10, marginRight: 10, fontSize: 12, marginBottom: '5px'}}>Valor máximo: {item.maxVal}</div> */}
                          <div style={{borderWidth: 1, backgroundColor: this.statusColor(item.status), color: '#fff', padding: '5px 10px', borderRadius: 10, marginRight: 10, fontSize: 12}}>{item.status}</div>
                        </Col>

                        <Col span={2}>
                          <Tooltip placement="top" title={'Detalles'}>
                            <Button shape="circle" type="primary" ghost style={{marginRight: 5}} href={`/indicador/${item.id}`} target='_blank'><FileSearchOutlined /></Button>
                          </Tooltip>
                        </Col>
                      </Row>
                  </List.Item>
                )}
              />
            </div>
          </Content>


        </Layout>
      </Layout>
    );
  }
}

export default Reportes;
