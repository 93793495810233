import { LikeOutlined, BellOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Layout, Menu, Card, Input, Button, Progress, message } from 'antd';
import React, { Component } from 'react';
import Logo from '../images/dcea_logo.png';
import Configuration from '../Configuration.js';
import { Navigate } from 'react-router-dom';
import TopMenu from '../Components/TopMenu';
import SideMenu from '../Components/SideMenu';
import axios from 'axios';

const { Header, Content, Sider } = Layout;

class Servidor extends Component {
  constructor(props) {
    super(props);

    const jwt = localStorage.getItem('token');
    this.headers = {authorization: `Bearer: ${jwt}`};

    this.state = {ram: 0, hardDrive: 0, evidence: 0};
  }

  componentDidMount() {
    this.getReport();
  }

  getReport() {
    const query = `{getSystem{ ram, hardDrive, evidence }}`;
    
    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al procesar tus credenciales');
      }
      
      if('errors' in resp.data) {
        return message.warning('Error al consultar el indicador.');
      }

      const match = resp.data.data.getSystem?.hardDrive.match(/(\d+)%/);
      const usage = match ? parseInt(match[1], 10) : null;
      
      const matchRam = resp.data.data.getSystem?.ram.match(/Mem:\s+\d+\s+\d+\s+(\d+)/);
      const freeMemory = matchRam ? parseInt(matchRam[1], 10) : null;

      const matchEv = resp.data.data.getSystem?.evidence.match(/(\d+M)/);
      const size = matchEv ? matchEv[1] : null;

      this.setState({ hardDrive: usage, ram: freeMemory, evidence: size.replace("M", "") });
    });
  }

  // Render UI
  render() {
    if(this.state.redirect) {
      return (
        <Navigate to={this.state.redirectUrl} />
      );
    }

    return (
      <Layout>
        {/* Top menu */}
        <TopMenu />

        <Layout className="site-layout">
          <SideMenu />

          <Content style={{margin: '24px 16px 0', background: '#fff'}}>
            <div className="site-layout-background" style={{padding: 24, textAlign: 'center', height: '85vh', overflowY: 'scroll'}}>

              {/* Header seccions */}
              <div className='container-between' style={{ marginBottom: '30px' }}>
                <h3>Status de servidor</h3>
              </div>

              {/* Content */}
              <div className="container-around">
                <div style={{border: '1px solid #ddd', borderRadius: 15, padding: 20, width: '45%'}}>
                  <h3>Disco duro</h3>
                  <Progress strokeLinecap="butt" percent={100 - this.state.hardDrive} />
                  <br />
                </div>

                <div style={{border: '1px solid #ddd', borderRadius: 15, padding: 20, width: '45%'}}>
                  <h3>Espacio usado por evidencia {this.state.evidence}</h3>
                  <Progress strokeLinecap="butt" percent={ ((this.state.evidence * 100) / 8000) } />
                  {/* <div style={{textAlign: 'center'}}>
                    <Button type="primary"><a href="/limpiar">Limpiar</a></Button>
                  </div> */}
                </div>
              

                <div style={{border: '1px solid #ddd', borderRadius: 15, padding: 20, width: '45%', marginTop: 40}}>
                  <h3>Uso de RAM</h3>
                  <Progress strokeLinecap="butt" type="circle" percent={( 100 - ((this.state.ram * 100) / 4000) )} />
                </div>

                <div style={{border: '1px solid #ddd', borderRadius: 15, padding: 20, width: '45%', marginTop: 40}}>
                  <h3 style={{marginBottom: 20}}>Procesos y aplicaciones</h3>
                  
                  <div className='container-between' style={{borderBottom: '1px solid #ddd', marginBottom: 10, paddingBottom: 10}}>
                    <p>API</p>
                    <CheckCircleOutlined style={{color: 'green'}} />
                  </div>

                  <div className='container-between' style={{borderBottom: '1px solid #ddd', marginBottom: 10, paddingBottom: 10}}>
                    <div>
                      <p>Interfaz</p>
                      {/* <Button type="primary">Arrancar</Button> */}
                    </div>
                    {/* <CloseCircleOutlined style={{color: 'red'}} /> */}
                    <CheckCircleOutlined style={{color: 'green'}} />
                  </div>

                  <div className='container-between' style={{borderBottom: '1px solid #ddd', marginBottom: 10, paddingBottom: 10}}>
                    <p>Base de Datos</p>
                    <CheckCircleOutlined style={{color: 'green'}} />
                  </div>
                </div>
              </div>

            </div>
          </Content>


        </Layout>
      </Layout>
    );
  }
}

export default Servidor;
