import { DeleteOutlined, EditOutlined, SearchOutlined, PlusOutlined, BellOutlined } from '@ant-design/icons';
import { Layout, Menu, Card, Input, Button, Modal, Form, message, Tag, List, Avatar } from 'antd';
import React, { Component } from 'react';
import Logo from '../images/dcea_logo.png';
import axios from 'axios';
import Configuration from '../Configuration.js';
import { Navigate } from 'react-router-dom';
import TopMenu from '../Components/TopMenu';
import SideMenu from '../Components/SideMenu';

const { Meta } = Card;
const { Search } = Input;
const { Header, Content, Sider } = Layout;
const items = Configuration.sideMenu();

class Notificaciones extends Component {
  constructor(props) {
    super(props);

    // Auth
    const jwt = localStorage.getItem('token');
    this.headers = {authorization: `Bearer: ${jwt}`};

    this.state = {searching: false, redirect: false, redirectUrl: '', allRecords: [], records: [], loading: false, openModal: false, modalTitle: '', selectedRecord: {}};
  }

  componentDidMount() {
    this.getRecords();
  }

  getRecords() {
    const query = `{userNotifications{id, title, viewed, indicator{id, question}}}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al procesar tus credenciales');
      }
      
      if('errors' in resp.data) {
        // console.log(resp.data);
        return message.warning('Error al obtener los registros');
      }

      const response = resp.data.data.userNotifications;
      // console.log(response);
      this.setState({records: response});
    });
  }

  setViewed() {
    const query = `{setViewdNotifications{total}}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al procesar tus credenciales');
      }
      
      if('errors' in resp.data) {
        // console.log(resp.data);
        return message.warning('Error al obtener los registros');
      }

      const response = resp.data.data.setViewdNotifications;
      // console.log(response);
      this.getRecords();
    });
  }

  onSearch(words) {
    this.setState({searching: true});

    // Search record
    const list = this.state.allRecords.filter((el) => {
      if (el.title.match(words)) {
        return el;
      }
    });
    
    // Update list 
    this.setState({records: list, searching: false});
  }

  onClickMainMenu(item) {
    switch(item.key) {
      case '0':
        this.setState({redirect: true, redirectUrl: '/notificaciones'});
        break;
      case '1':
        this.setState({redirect: true, redirectUrl: '/login'});
        break;
    }
  }

  onClickSideMenu(item) {
    if (item.key != "2") {
      const route = Configuration.getRoute(item);
      this.setState({redirect: true, redirectUrl: route});
    }
  }

  handleCreateModal() {
    this.setState({openModal: true, modalTitle: 'Nuevo área'});
  }

  handleSubmitModal(data) {
    this.setState({loading: true});

    // Update or create
    if ('name' in this.state.selectedRecord) {
      this.updateRecord(data);
    } else {
      this.createRecord(data);
    }
  }

  createRecord(record) {
    // Add new record
    const newRecords = this.state.records;
    record.id = newRecords.length + 1;
    record.users = 0;
    record.indicators = 0;
    newRecords.push(record);
    // Close modal
    this.setState({openModal: false, modalTitle: '', loading: false, records: newRecords});
  }

  handleEditModal(record) {
    this.setState({openModal: true, modalTitle: 'Editar área', selectedRecord: record});
  }

  updateRecord(record) {
    const newRecords = this.state.records;
    const selected = this.state.selectedRecord;
    // Find record
    const list = newRecords.map(el => { 
      if (el.id == selected.id) {
        let newRecord = {...el};
        newRecord.name = record.name;
        return newRecord;
      } else {
        return el;
      }
    });

    // Close modal
    this.setState({openModal: false, modalTitle: '', loading: false, records: list});
  }

  deleteRecord(record) {
    const filter = this.state.records.findIndex((el, index) => {
      if (record.id == el.id) {
        return el;
      }
    });
    
    const newRecords = this.state.records;
    delete newRecords[filter];
    this.setState({records: newRecords});
  }

  handleCancelModal() {
    this.setState({openModal: false, modalTitle: ''});
  }

  openDetails(record) {
    this.setState({redirect: true, redirectUrl: `/area/${record.id}`});
  }



  // Render UI
  render() {
    if(this.state.redirect) {
      return (
        <Navigate to={this.state.redirectUrl} />
      );
    }

    return (
      <Layout>
        {/* Top menu */}
        <TopMenu />

        {/* Content with two columns */}
        <Layout className="site-layout">
          <SideMenu />

          <Content style={{margin: '24px 16px 0', background: '#fff'}}>
            <div style={{padding: 24, textAlign: 'center'}}>

              {/* Header seccions */}
              <div className='container-between' style={{ marginBottom: '30px' }}>
                <h3>Notificaciones </h3>

                <div className='container-end' style={{width: '50%'}}>
                  <Button type="primary" onClick={this.setViewed.bind(this)}>Marcar como leidas</Button>
                </div>
              </div>

              {/* Content */}
              <div className="container-around">
                <List
                  itemLayout="horizontal"
                  style={{width: '100%'}}
                  dataSource={this.state.records}
                  renderItem={(item) => (
                    <List.Item>
                      <List.Item.Meta
                        avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
                        title={<a href="#">{item.title}</a>}
                        description={
                          <div>
                            {item.viewed ? <Tag color="green">Vista</Tag> : <Tag color="gold">Nueva</Tag>}
                          </div>
                        }
                      />
                      <div>
                        <a href={`/indicador/${item.indicator?.id}`} type="primary" size="small">Ver indicador</a>
                      </div>
                    </List.Item>
                  )}
                />
              </div>

            </div>
          </Content>


        </Layout>
      </Layout>
    );
  }
}

export default Notificaciones;
