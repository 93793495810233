import { AppstoreOutlined, BookOutlined, UserOutlined, LineChartOutlined, ExceptionOutlined } from '@ant-design/icons';
import { Layout } from 'antd';
import React, { Component } from 'react';

const { Sider } = Layout;

class TopMenu extends Component {
  render() {
    return (
      <Sider style={{overflow: 'auto', minHeight: '90vh', left: 0, top: 0, bottom: 0, background: '#1B3C6A'}}>
        <ul style={{listStyle: 'none', marginTop: 20, padding: 0}}>
          <li style={{paddingLeft: '25%', paddingTop: 15, paddingBottom: 15}}><a href='/organos-acreditadores' style={{color: '#fff'}}><AppstoreOutlined /> Órganos</a></li>
          <li style={{paddingLeft: '25%', paddingTop: 15, paddingBottom: 15}}><a href='/areas' style={{color: '#fff'}}><BookOutlined /> Áreas</a></li>
          <li style={{paddingLeft: '25%', paddingTop: 15, paddingBottom: 15}}><a href='/roles' style={{color: '#fff'}}><UserOutlined /> Roles</a></li>
          <li style={{paddingLeft: '25%', paddingTop: 15, paddingBottom: 15}}><a href='/usuarios' style={{color: '#fff'}}><UserOutlined /> Usuarios</a></li>
          <li style={{paddingLeft: '25%', paddingTop: 15, paddingBottom: 15}}><a href='/reportes' style={{color: '#fff'}}><LineChartOutlined /> Reporte</a></li>
          <li style={{paddingLeft: '25%', paddingTop: 15, paddingBottom: 15}}><a href='/servidor' style={{color: '#fff'}}><ExceptionOutlined /> Servidor</a></li>
        </ul>
      </Sider>
    );
  }
}

export default TopMenu;