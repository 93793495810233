import { Layout, Menu, Card, Button, Tooltip, Space, Alert, message } from 'antd';
import React, { Component } from 'react';
import axios from 'axios';
import Configuration from '../Configuration.js';
import { Navigate, useParams } from 'react-router-dom';

const { Content } = Layout;

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

class IndicadorReporte extends Component {
  constructor(props) {
    super(props);

    const comments = [];
    const progress = [];

    // Auth
    const jwt = localStorage.getItem('token');
    this.headers = {authorization: `Bearer: ${jwt}`};

    // Set state
    this.state = {searching: false, records: [], acreditor: {}, carrer: {}, category: {}, criteria: {}, fundations: [], redirect: false, redirectUrl: '', targets: [], loading: false, openModal: false, modalTitle: '', selectedRecord: {}, record: {}, comments: comments, showAlert: false, alertTitle: '', alertMessage: '', progress: progress, rol: '', itemsSideMenu: [], modalError: false, fileList: [], uploading: false, fileDescription: "", fundationValue: "", editFundation: false, idFundation: 0, evidences: [], evidencesTmp: [], selectedStatus: ''};
  }

  componentDidMount() {
    const params = this.props.params;
    if ('id' in params) {
      const rol =  localStorage.getItem('rol');
      const id =  localStorage.getItem('userid');
      this.setState({rol: rol, currentUser: id});

      this.getIndicator(params.id);
      // this.getFiles(params.id);
      this.getComments(params.id);
      this.getAdvances(params.id);
    }

    // Get extra info
    const acreditorId = localStorage.getItem('acreditorId');
    if (acreditorId) {
      this.getAcreditor(acreditorId);
    }

    const carrerId = localStorage.getItem('carrerId');
    if (carrerId) {
      this.getCarrer(carrerId);
    }

    const categoryId = localStorage.getItem('categoryId');
    if (categoryId) {
      this.getCategory(categoryId);
    }

    const criteriaId =  localStorage.getItem('criteriaId');
    if (criteriaId) {
      this.getCriteria(criteriaId);
    }

    // Set menu and rol
    const rol =  localStorage.getItem('rol');
    if (rol) {
      if (rol === 'Admin' || rol === 'Admin auxiliar') {
        this.setState({rol: rol, itemsSideMenu: Configuration.sideMenu()});  
      }
      if (rol === 'coordinador') {
        this.setState({rol: rol, itemsSideMenu: Configuration.sideMenuCoor()});  
      }
      if (rol === 'Profesor') {
        this.setState({rol: rol, itemsSideMenu: Configuration.sideMenuProf()});  
      }
    }
  }

  getIndicator(id) {
    const query = `{getIndicator(id: ${id}){id, folio, question, status, maxVal, notes, reason, user{id, name}, area{id, name}, advancePorcent, calculusAdvance}}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al procesar tus credenciales');
      }
      
      if('errors' in resp.data) {
        return message.warning('Error al consultar el indicador.');
      }

      this.setState({record: resp.data.data.getIndicator});
      this.getIndicatorFundations(id);
    });
  }

  getIndicatorFundations(id) {
    const rol =  localStorage.getItem('rol');
    const query = `{listIndicatorFundationsReport(id: ${id}){id,description,insertedAt}}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al procesar tus credenciales');
      }
      
      if('errors' in resp.data) {
        return message.warning('Error al consultar el indicador.');
      }

      if (resp.data.data.listIndicatorFundationsReport.length > 0) {
        this.setState({fundations: resp.data.data.listIndicatorFundationsReport, fundationValue: resp.data.data.listIndicatorFundationsReport[0].description});
        this.getFiles(id);
      }
    });
  }

  getAcreditor(id) {
    const query = `{getAcreditor(id: ${id}){id,shortName}}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al procesar tus credenciales');
      }
      
      if('errors' in resp.data) {
        return message.warning('Error al consultar el órgano acreditador.');
      }

      this.setState({acreditor: resp.data.data.getAcreditor});
    });
  }

  getCarrer(id) {
    const query = `{getCarrer(id: ${id}){id,name}}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al procesar tus credenciales');
      }
      
      if('errors' in resp.data) {
        return message.warning('Error al consultar la carrera.');
      }

      this.setState({carrer: resp.data.data.getCarrer});
    });
  }

  getCategory(id) {
    const query = `{getCriteriaCategory(id: ${id}){id,name,folio}}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al procesar tus credenciales');
      }
      
      if('errors' in resp.data) {
        return message.warning('Error al consultar la categoria.');
      }

      this.setState({category: resp.data.data.getCriteriaCategory});
    });
  }

  getCriteria(id) {
    const query = `{getCriteria(id: ${id}){id,name,number}}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al procesar tus credenciales');
      }
      
      if('errors' in resp.data) {
        return message.warning('Error al consultar el criterio.');
      }

      this.setState({criteria: resp.data.data.getCriteria});
    });
  }

  filterOption(inputValue, option) { 
    return option.name.indexOf(inputValue) > -1;
  }

  handleChange(newTargetKeys) {
    this.setState({targets: newTargetKeys});
  }

  statusColor(status) {
    switch(status) {
      case 'Incompleto':
        return '#C7AD08';
      case 'Por revisar':
        return '#C7AD08';
      case 'En revisión':
        return '#FA8C16';
      case 'Con errores':
        return '#FB4D4F';
      case 'Autorizado':
        return '#52C51A';
      default:
        return '#C7AD08';
    }
  }

  getFiles(id) {
    const query = `{listEvidence(id: ${id}){id,description,file}}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al obtener la evidencia.');
      }
      
      if('errors' in resp.data) {
        return message.warning('Hubo un error al obtener la evidencia.');
      }
      
      // console.log(resp.data.data.listEvidence);
      this.setState({evidences: resp.data.data.listEvidence});
    });
  }

  getComments(id) {
    const query = `{listComments(id: ${id}){id,description,insertedAt,user{id,name}}}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al obtener la evidencia.');
      }
      
      if('errors' in resp.data) {
        return message.warning('Hubo un error al obtener la evidencia.');
      }
      
      this.setState({comments: resp.data.data.listComments});
    });
  }

  getAdvances(id) {
    const query = `{listAdvances(id: ${id}){id,title,insertedAt}}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al obtener los avances.');
      }
      
      if('errors' in resp.data) {
        return message.warning('Hubo un error al obtener los avances.');
      }
      
      // console.log(resp.data.data.listAdvances);
      this.setState({progress: resp.data.data.listAdvances});
    });
  }

  // Render UI
  render() {
    if(this.state.redirect) {
      return (
        <Navigate to={this.state.redirectUrl} />
      );
    }

    const menu = (
      <Menu>
        <Menu.Item key={`menu-2`}>Por revisar</Menu.Item>
        <Menu.Item key={`menu-1`}>Incompleto</Menu.Item>
        <Menu.Item key={`menu-4`}>Con errores</Menu.Item>
        <Menu.Item key={`menu-5`}>Autorizado</Menu.Item>
      </Menu>
    );

    return (
      <Layout>
        {/* Alert */}
        <Alert
          message={this.state.alertTitle}
          description={this.state.alertMessage}
          type="info" 
          style={{position: 'fixed', right: 0, top: 10, display: this.state.showAlert ? 'block' : 'none'}}
        />

        <Layout>

          <Content style={{margin: '24px 16px 0', background: '#fff'}}>
            <div className="site-layout-background" style={{padding: 24, textAlign: 'center'}}>

              {/* Content */}
              <div className="container-between" style={{alignItems: 'flex-start'}}>
                <div style={{width: '100%', padding: 20}}>
                  {/* Información */}
                  <Card style={{ width: '100%', textAlign: 'left' }}>
                    <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: 5}}>
                      <a href="javascript: window.print();" style={{borderRadius: 5, borderColor: '#ccc', borderWidth: 1, borderStyle: 'solid', padding: 3, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="1024" height="1024" viewBox="0 0 1024 1024" style={{color: '#ccc', height: 20, width: 25}}><path fill="currentColor" d="M820 436h-40c-4.4 0-8 3.6-8 8v40c0 4.4 3.6 8 8 8h40c4.4 0 8-3.6 8-8v-40c0-4.4-3.6-8-8-8m32-104H732V120c0-4.4-3.6-8-8-8H300c-4.4 0-8 3.6-8 8v212H172c-44.2 0-80 35.8-80 80v328c0 17.7 14.3 32 32 32h168v132c0 4.4 3.6 8 8 8h424c4.4 0 8-3.6 8-8V772h168c17.7 0 32-14.3 32-32V412c0-44.2-35.8-80-80-80M360 180h304v152H360zm304 664H360V568h304zm200-140H732V500H292v204H160V412c0-6.6 5.4-12 12-12h680c6.6 0 12 5.4 12 12z"/></svg>
                      </a>
                    </div>

                    <div className='container-between' style={{alignItems: 'flex-start', flexWrap: 'wrap', flexDirection: 'row'}}>
                      <div className="shadow ant-col-11" style={{padding: 10, marginBottom: 10}}>
                        <h4>Número de categoría: <b>{this.state.category.folio}</b></h4>
                        <h4>Categoría: <b>{this.state.category.name}</b></h4>
                      </div>
                      
                      <div className="shadow ant-col-11" style={{padding: 10, marginBottom: 10}}>
                        <h4>Número de criterio: <b>{this.state.criteria.number}</b></h4>
                        <h4>Criterio: <b>{this.state.criteria.name}</b></h4>
                      </div>
                    </div>
                    

                    <div style={{marginTop: 20, marginBottom: 20, paddingBottom: 20, borderBottom: `1px solid #f1f1f1`, paddingTop: 20, borderTop: `1px solid #f1f1f1`, display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                      <Button style={{backgroundColor: this.statusColor(this.state.record.status)}}>
                        <Space>{this.state.record.status}</Space>
                      </Button>

                      {this.state.record.status === 'Con errores' || this.state.record.status === 'Incompleto' ? 
                        <div style={{marginTop: 10, marginBottom: 5, color: '#FB4D4F'}}>Motivo: {this.state.record.reason}</div>
                      :null}
                    </div>
                    
                    <div className='container-between' style={{alignItems: 'flex-start', flexWrap: 'wrap', flexDirection: 'row'}}>
                      <div className="ant-col-11">
                        <h4 style={{marginBottom: 0}}>Número de indicador: <b>{this.state.record.folio}</b></h4>
                        <h4 style={{marginBottom: 0}}>Valor máximo: <b>{this.state.record.maxVal}</b></h4>
                        {this.state.acreditor.shortName !== "CONAET" ?
                          <h4 style={{marginBottom: 0}}>Porciento de avance: <b>{this.state.record.advancePorcent}%</b></h4>
                        : null}
                      </div>

                      <div className="ant-col-11">
                        <h4 style={{marginBottom: 0}}>Carrera: <b>{this.state.carrer.name}</b></h4>
                        <h4 style={{marginBottom: 0}}>Órgano acreditador: <b>{this.state.acreditor.shortName}</b></h4>
                      </div>
                    </div>
                    
                    <br></br>
                    <br></br>
                    
                    <h4 style={{textAlign: 'left', borderTop: '1px solid rgb(241, 241, 241)', paddingTop: 15}}><strong>{this.state.record.question}</strong></h4>
                    <div style={{color: '#777'}} dangerouslySetInnerHTML={{ __html: this.state.record?.notes ? this.state.record.notes: 'Notas adicionales....' }} />
                  </Card>

                  {/* Llenado de datos */}
                  <Card title="Fundamentación" style={{ width: '100%', textAlign: 'left', marginTop: 20 }}>
                    <div style={{backgroundColor: '#F9FCFF', border: '1px solid #E8F4FF', padding: 15, borderRadius: 5}}>
                      <div className="ant-form-item" style={{marginBottom: 50}}>
                        <div className="ant-row ant-form-item-row">

                          <div className="ant-col ant-col-24 ant-form-item-control">
                            <div dangerouslySetInnerHTML={{ __html: this.state.fundationValue }} />
                          </div>

                        </div>
                      </div>
                    </div>
                  </Card>

                  {/* Advance */}
                  {this.state.acreditor.shortName !== "CONAET" ?
                    <div className="ant-card ant-card-bordered css-dev-only-do-not-override-1x0dypw" style={{ width: '100%', textAlign: 'left', marginTop: 20 }}>
                      <div className="ant-card-head">
                        <div className="ant-card-head-wrapper">
                          <div className="ant-card-head-title">Cálculo de porcentaje alcanzado</div>
                        </div>
                      </div>
                      <div className="ant-card-body">
                        <div style={{backgroundColor: '#F9FCFF', border: '1px solid #E8F4FF', padding: 15, borderRadius: 5}}>
                          <div className="ant-form-item" style={{marginBottom: 50}}>
                            <div className="ant-row ant-form-item-row">
                              <div className="ant-col ant-col-24 ant-form-item-control">
                              {this.state.record.calculusAdvance}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  : null}
                </div>
              </div>

            </div>
          </Content>


        </Layout>
      </Layout>
    );
  }
}

export default withParams(IndicadorReporte);
