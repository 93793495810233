import { UploadOutlined, DownOutlined, FileOutlined, DeleteOutlined } from '@ant-design/icons';
import { Layout, Menu, Card, Input, Button, Popconfirm, Modal, Form, Timeline, List, Skeleton, Tooltip, Upload, Tag, Dropdown, Space, Alert, message, Breadcrumb } from 'antd';
import React, { Component } from 'react';
import axios from 'axios';
import Configuration from '../Configuration.js';
import { Navigate, useParams } from 'react-router-dom';
import TopMenu from '../Components/TopMenu';
import SideMenu from '../Components/SideMenu';
import SideMenuProfesor from '../Components/SideMenuProfesor';
// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill-new';
import 'react-quill-new/dist/quill.snow.css';

const { Content } = Layout;

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

class IndicadorDetalles extends Component {
  constructor(props) {
    super(props);

    const comments = [];
    const progress = [];

    // Auth
    const jwt = localStorage.getItem('token');
    this.headers = {authorization: `Bearer: ${jwt}`};

    // Set state
    this.state = {searching: false, records: [], acreditor: {}, carrer: {}, category: {}, criteria: {}, fundations: [], redirect: false, redirectUrl: '', targets: [], loading: false, openModal: false, modalTitle: '', selectedRecord: {}, record: {}, comments: comments, showAlert: false, alertTitle: '', alertMessage: '', progress: progress, rol: '', itemsSideMenu: [], modalError: false, fileList: [], uploading: false, fileDescription: "", fundationValue: "", editFundation: false, idFundation: 0, evidences: [], evidencesTmp: [], selectedStatus: '', inputCalculusAdvance: ''};
  }

  componentDidMount() {
    const params = this.props.params;
    if ('indicador' in params) {
      const rol =  localStorage.getItem('rol');
      const id =  localStorage.getItem('userid');

      this.setState({rol: rol, currentUser: id});
      this.getIndicator(params.indicador);
      this.getComments(params.indicador);
      this.getAdvances(params.indicador);
    }

    // Set menu and rol
    const rol =  localStorage.getItem('rol');
    if (rol) {
      if (rol === 'Admin' || rol === 'Admin auxiliar') {
        this.setState({rol: rol, itemsSideMenu: Configuration.sideMenu()});  
      }
      if (rol === 'coordinador') {
        this.setState({rol: rol, itemsSideMenu: Configuration.sideMenuCoor()});  
      }
      if (rol === 'Profesor') {
        this.setState({rol: rol, itemsSideMenu: Configuration.sideMenuProf()});  
      }
    }
  }

  getIndicator(id) {
    const query = `{getIndicator(id: ${id}){ id, folio, question, status, maxVal, notes, reason,user{id,name}, area{id,name}, criteria{id, name, number}, acreditor{id, shortName}, carrer{id, name}, criteriaCategory{id,name, folio}, advancePorcent, calculusAdvance }}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al procesar tus credenciales');
      }
      
      if('errors' in resp.data) {
        return message.warning('Error al consultar el indicador.');
      }

      this.setState({record: resp.data.data.getIndicator});
      this.getIndicatorFundations(id);

      // Validate acreditor data
      // console.log(this.state.rol);
      if (!resp.data.data.getIndicator.acreditor && this.state.rol === "Admin") {
        this.updateIndicatorExtraData(id);
      }
    });
  }

  updateIndicatorExtraData(id) {
    const acreditorID = this.props.params.acreditor;
    const carrerID = this.props.params.carrer;
    const categoryID = this.props.params.categoria;

    const query = `mutation{updateIndicatorExtraData(id: ${id}, acreditor: ${acreditorID}, carrer: ${carrerID}, category: ${categoryID}){id}}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al procesar tus credenciales');
      }
      
      if('errors' in resp.data) {
        console.log(resp.data)
        return message.warning('Error al consultar el órgano acreditador.');
      }

      console.log(resp.data.data.updateIndicatorExtraData);
    });
  }

  getIndicatorFundations(id) {
    const rol =  localStorage.getItem('rol');
    const query = `{listIndicatorFundations(id: ${id}){id,description,insertedAt}}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al procesar tus credenciales');
      }
      
      if('errors' in resp.data) {
        return message.warning('Error al consultar el indicador.');
      }

      if (resp.data.data.listIndicatorFundations.length > 0) {
        this.setState({fundations: resp.data.data.listIndicatorFundations, fundationValue: resp.data.data.listIndicatorFundations[0].description});
        this.getFiles(id);
      }
    });
  }

  getAcreditor(id) {
    const query = `{getAcreditor(id: ${id}){id,shortName}}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al procesar tus credenciales');
      }
      
      if('errors' in resp.data) {
        return message.warning('Error al consultar el órgano acreditador.');
      }

      this.setState({acreditor: resp.data.data.getAcreditor});
    });
  }

  getCarrer(id) {
    const query = `{getCarrer(id: ${id}){id,name}}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al procesar tus credenciales');
      }
      
      if('errors' in resp.data) {
        return message.warning('Error al consultar la carrera.');
      }

      this.setState({carrer: resp.data.data.getCarrer});
    });
  }

  getCategory(id) {
    const query = `{getCriteriaCategory(id: ${id}){id,name,folio}}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al procesar tus credenciales');
      }
      
      if('errors' in resp.data) {
        return message.warning('Error al consultar la categoria.');
      }

      this.setState({category: resp.data.data.getCriteriaCategory});
    });
  }

  getCriteria(id) {
    const query = `{getCriteria(id: ${id}){id,name,number}}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al procesar tus credenciales');
      }
      
      if('errors' in resp.data) {
        return message.warning('Error al consultar el criterio.');
      }

      this.setState({criteria: resp.data.data.getCriteria});
    });
  }

  sendResponse() {
    if (this.state.fundations.length > 0) {
      this.updateFundation(this.state.fundations[0].id);
    } else {
      const query = `mutation{createIndicatorFundation(indicatorId: ${this.state.record.id}){id}}`;
      let formData = new FormData();
      formData.append('description', this.state.fundationValue);
      
      fetch(`${Configuration.apiServer}/api/v1?query=${query}`, {
        method: 'POST',
        body: formData,
        headers: this.headers
      })
      .then((res) => res.json())
      .then(() => {
        message.success('La evidencia ha sido creada.');
        this.setState({fundationValue: "", editFundation: false, idFundation: 0});
        this.getIndicatorFundations(this.state.record.id);
      })
      .catch(() => {
        this.setState({uploading: false, fileList: []});
        message.error('Error al registrar la fundamentación.');
      });
    }
  }

  updateFundation(idFundation) {
    const query = `mutation{updateIndicatorFundation(id: ${idFundation}){id}}`;
    let formData = new FormData();
    formData.append('description', this.state.fundationValue);

    fetch(`${Configuration.apiServer}/api/v1?query=${query}`, {
      method: 'POST',
      body: formData,
      headers: this.headers
    })
    .then((res) => res.json())
    .then(() => {
      message.success('La evidencia ha sido actualizada.');
      this.setState({fundationValue: "", editFundation: false, idFundation: 0});
      this.getIndicatorFundations(this.state.record.id);
    })
    .catch(() => {
      this.setState({uploading: false, fileList: []});
      message.error('Error al registrar la fundamentación.');
    });
  }

  handleCreateModal() {
    this.setState({openModal: true, modalTitle: 'Nuevo indicador'});
  }

  handleSubmitModal(data) {
    this.setState({loading: true});

    // Update or create
    if ('name' in this.state.selectedRecord) {
      this.updateRecord(data);
    } else {
      this.createRecord(data);
    }
  }

  createRecord(record) {
    // Add new record
    const newRecords = this.state.records;
    record.id = record.length + 1;
    record.key = `ind-${record.length + 1}`;
    newRecords.push(record);
    // Close modal
    setTimeout(() => {
      this.setState({openModal: false, modalTitle: '', loading: false, records: newRecords});
    }, 1000);
  }

  handleEditModal(record) {
    this.setState({openModal: true, modalTitle: 'Editar indicador', selectedRecord: record});
  }

  updateRecord(record) {
    const newRecords = this.state.records;
    const selected = this.state.selectedRecord;
    // console.log(record);
    // Find record
    const list = newRecords.map(el => { 
      if (el.id === selected.id) {
        let newRecord = {...record};
        newRecord.id = el.id;
        newRecord.key = el.key;
        return newRecord;
      } else {
        return el;
      }
    });

    // Close modal
    setTimeout(() => {
      this.setState({openModal: false, modalTitle: '', loading: false, records: list});
    }, 1000);
  }

  deleteRecord(record) {
    const filter = this.state.records.findIndex((el, index) => record.id === el.id);
    
    const newRecords = this.state.records;
    delete newRecords[filter];
    this.setState({records: newRecords});
  }

  handleCancelModal() {
    this.setState({openModal: false, modalTitle: ''});
  }

  filterOption(inputValue, option) { 
    return option.name.indexOf(inputValue) > -1;
  }

  handleChange(newTargetKeys) {
    this.setState({targets: newTargetKeys});
  }

  statusColor(status) {
    switch(status) {
      case 'Incompleto':
        return '#C7AD08';
      case 'Por revisar':
        return '#C7AD08';
      case 'En revisión':
        return '#FA8C16';
      case 'Con errores':
        return '#FB4D4F';
      case 'Autorizado':
        return '#52C51A';
      default:
        return '#C7AD08';
    }
  }

  async changeStatus(status) {
    if (status === 'Con errores' || status === 'Incompleto') {
      // Show modal
      this.setState({modalError: true, selectedStatus: status});
    } else {
      const query = `mutation{statusIndicator(id: ${this.state.record.id}, status: "${status}"){id}}`;

      axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
        if(resp.status !== 200) {
          return message.warning('Hubo un error al procesar tus credenciales');
        }
        
        if('errors' in resp.data) {
          return message.warning('Error al actualizar el status.');
        }

        this.getIndicator(this.state.record.id);
        this.sendNotification('Listo', 'El status fue actualizado');
        this.appendProgress(`Cambio de status: ${status}`);
      });
    }
  }

  handleCancelModalError() {
    this.setState({modalError: false});
  }

  handleSubmitModalError(record) {
    const query = `mutation{denyIndicator(id: ${this.state.record.id}, status: "${this.state.selectedStatus}", reason: "${record.error}"){id}}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al procesar tus credenciales');
      }
      
      if('errors' in resp.data) {
        // console.log(resp.data);
        return message.warning('Error al actualizar el status.');
      }

      this.getIndicator(this.state.record.id);
      this.sendNotification('Listo', 'El status fue actualizado');
      this.appendProgress(`Cambio de status: Con errores`);
      this.setState({modalError: false});
    });
  }

  async sendComment(data) {
    // console.log(data);
    if ('comment' in data) {
      const now = new Date();
      const com = {
        actions: [],
        author: 'Juan Perez',
        avatar: 'https://joeschmoe.io/api/v1/random',
        content: (
          <p>{data.comment}</p>
        ),
        datetime: (
          <Tooltip title="2016-11-22 11:22:33"><span>{now.toLocaleString()}</span></Tooltip>
        ),
      };
      let comments = this.state.comments;
      comments.push(com);
      this.setState({comments: comments});
      this.sendNotification('Listo', 'El comentario fue agregado');
      this.appendProgress('Nuevo comentario');
    }
  }

  appendProgress(title) {
    let prog = this.state.progress;
    const now = new Date();
    const item = {id: prog.length + 1, title: title, date: now.toLocaleString()};
    prog.push(item);
    
    this.setState({progress: prog});
  }

  sendNotification(title, message) {
    this.setState({showAlert: true, alertMessage: message, alertTitle: title});
    const instance = this;
    setTimeout(() => {
      instance.setState({showAlert: false});
    }, 3000);
  }

  beforeUpload(file) {
    let files = [...this.state.evidencesTmp];
    files.push(file);
    this.setState({evidencesTmp: files});
    return false;
  }
  
  removeUpload(file) {
    const index = this.state.evidencesTmp.indexOf(file);
    let newFileList = this.state.evidencesTmp.slice();
    newFileList.splice(index, 1);
    this.setState({evidencesTmp: newFileList});
  }

  handleUpload() {
    // if (this.state.fileDescription === "") return message.error("Necesitas una descripción.");

    let formData = new FormData();
    this.state.evidencesTmp.forEach((file) => {
      formData.append('file', file);
    });
    this.setState({uploading: true});
    // Upload file
    const query = `mutation{createEvidence(id: ${this.state.record.id}, description: " "){id}}`;
    // console.log(query);
    
    fetch(`${Configuration.apiServer}/api/v1?query=${query}`, {
      method: 'POST',
      body: formData,
      headers: this.headers
    })
    .then((res) => res.json())
    .then(() => {
      this.setState({uploading: false, evidencesTmp: []});
      message.success('La evidencia se ha subido correctamente.');
    })
    .catch(() => {
      this.setState({uploading: false, evidencesTmp: []});
      message.error('Hubo un error al subir la evidencia.');
    })
    .finally(() => {
      this.getFiles(this.state.record?.id);
    });
  }

  getFiles(id) {
    const query = `{listEvidence(id: ${id}){id,description,file}}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al obtener la evidencia.');
      }
      
      if('errors' in resp.data) {
        return message.warning('Hubo un error al obtener la evidencia.');
      }
      
      // console.log(resp.data.data.listEvidence);
      this.setState({evidences: resp.data.data.listEvidence});
      this.addEvidenceToResponse(resp.data.data.listEvidence);
    });
  }

  addEvidenceToResponse(files) {
    let response = this.state.fundationValue;
    
    // Add title for evidences
    // if (!response || ( !response.match('<br/><h3>Evidencias</h3>') && !response.match('<p><br></p><h3>Evidencias</h3>') )) {
    //   let firsResponse = "";
    //   if (response) {
    //     firsResponse = response;
    //   }
    //   response = `${firsResponse}<br/><h3>Evidencias</h3>`;
    // }

    // Clean all evidences
    // When is live edition
    // const reg1 = /(.*)(<br\/><h3>Evidencias<\/h3>)(.*)/;
    // const allFiles = response.match(reg1);
    // if (allFiles && allFiles.length === 4) {
    //   response = response.replace(allFiles[3], '');

    //   // Validate file by file
    //   files.forEach((file, index) => {
    //     // Add new evidence
    //     response = `${response}<p><a href="https://dcea-api.innovaster.co${file.file}" rel="noopener noreferrer" target="_blank">Evidencia #${index+1}: ${file.description}</a></p>`;
    //   });
    //   // console.log(response);
    //   this.setState({['fundationValue']: response});
    // }

    // Clean all evidences
    // After saved in DB
    // const reg2 = /(.*)(<p><br><\/p><h3>Evidencias<\/h3>)(.*)/;
    // const allFiles2 = response.match(reg2);
    // if (allFiles2 && allFiles2.length === 4) {
    //   response = response.replace(allFiles2[3], '');

    //   // Validate file by file
    //   files.forEach((file, index) => {
    //     // Add new evidence
    //     response = `${response}<p><a href="https://dcea-api.innovaster.co${file.file}" rel="noopener noreferrer" target="_blank">Evidencia #${index+1}: ${file.description}</a></p>`;
    //   });
    //   // console.log(response);
    //   this.setState({['fundationValue']: response});
    // }
  }

  deleteEvidence(item) {
    const query = `mutation{deleteEvidence(id: ${item.id}){id}}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error borrar la evidencia.');
      }
      
      if('errors' in resp.data) {
        return message.warning('Hubo un error borrar la evidencia.');
      }
      
      this.getFiles(this.state.record?.id);
    });
  }

  getComments(id) {
    const query = `{listComments(id: ${id}){id,description,insertedAt,user{id,name}}}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al obtener la evidencia.');
      }
      
      if('errors' in resp.data) {
        return message.warning('Hubo un error al obtener la evidencia.');
      }
      
      this.setState({comments: resp.data.data.listComments});
    });
  }

  createComment(form) {
    if (form.comment === "" || form.comment === " ") return message.warning("Necesitas escribir un comentario.");

    const query = `mutation{createIndicatorComment(indicatorId: ${this.state.record?.id}, comment: "${form.comment}"){id}}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error borrar la evidencia.');
      }
      
      if('errors' in resp.data) {
        return message.warning('Hubo un error borrar la evidencia.');
      }
      
      message.success('Tu comentario ha sido guardado.');
      this.getComments(this.state.record?.id);
    });
  }

  deleteComment(id) {
    const query = `mutation{deleteIndicatorComment(id: ${id}){id}}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al obtener la evidencia.');
      }
      
      if('errors' in resp.data) {
        return message.warning('Hubo un error al obtener la evidencia.');
      }
      
      message.success('Tu comentario ha sido eliminado.');
      this.getComments(this.state.record?.id);
    });
  }

  getAdvances(id) {
    const query = `{listAdvances(id: ${id}){id,title,insertedAt}}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al obtener los avances.');
      }
      
      if('errors' in resp.data) {
        return message.warning('Hubo un error al obtener los avances.');
      }
      
      // console.log(resp.data.data.listAdvances);
      this.setState({progress: resp.data.data.listAdvances});
    });
  }

  changeAdvancePorcent(input) {
    const qty = input.target.value;
    const id = this.state.record.id;
    const query = `mutation{updateIndicatorAdvance(id: ${id}, advance: "${qty}"){ id }}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al actualizar el indicador.');
      }
      
      if('errors' in resp.data) {
        return message.warning('Hubo un error al actualizar el indicador.');
      }
      
      return message.info('Indicador actualizado.');
      // console.log(resp.data.data.updateIndicatorAdvance);
    });
  }

  uploadCalculusAdvance() {
    const id = this.state.record.id;
    const query = `mutation{updateIndicatorCalculusAdvance(id: ${id}){ id }}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {advance: this.state.inputCalculusAdvance}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al actualizar el indicador.');
      }
      
      if('errors' in resp.data) {
        return message.warning('Hubo un error al actualizar el indicador.');
      }
      
      return message.info('Indicador actualizado.');
      // console.log(resp.data.data.updateIndicatorAdvance);
    });
  }

  // Render UI
  render() {
    if(this.state.redirect) {
      return (
        <Navigate to={this.state.redirectUrl} />
      );
    }

    const menu = (
      <Menu>
        <Menu.Item key={`menu-2`} onClick={() => { this.changeStatus('Por revisar') }}>Por revisar</Menu.Item>
        <Menu.Item key={`menu-1`} onClick={() => { this.changeStatus('Incompleto') }}>Incompleto</Menu.Item>
        <Menu.Item key={`menu-4`} onClick={() => { this.changeStatus('Con errores') }}>Con errores</Menu.Item>
        <Menu.Item key={`menu-5`} onClick={() => { this.changeStatus('Autorizado') }}>Autorizado</Menu.Item>
      </Menu>
    );
    // const menu = [
    //   { key: 'menu-2', label: 'Por revisar', onClick: () => this.changeStatus('Por revisar') },
    //   { key: 'menu-1', label: 'Incompleto', onClick: () => this.changeStatus('Incompleto') },
    //   { key: 'menu-4', label: 'Con errores', onClick: () => this.changeStatus('Con errores') }
    //   { key: 'menu-5', label: 'Autorizado', onClick: () => this.changeStatus('Autorizado') }
    // ];

    return (
      <Layout>
        {/* Alert */}
        <Alert
          message={this.state.alertTitle}
          description={this.state.alertMessage}
          type="info" 
          style={{position: 'fixed', right: 0, top: 10, display: this.state.showAlert ? 'block' : 'none'}}
        />

        {/* Modal for new and edit */}
        <Modal
          open={this.state.openModal}
          title={this.state.modalTitle}
          onOk={this.handleSubmitModal.bind(this)}
          onCancel={this.handleCancelModal.bind(this)}
          footer={false}
        >
          <Form name="basic" labelCol={{span: 8}} wrapperCol={{span: 16}} initialValues={this.state.selectedRecord} onFinish={this.handleSubmitModal.bind(this)} onFinishFailed={() => {}} autoComplete="off">
            <Form.Item label="Nombre completo" name="name" rules={[{required: true, message: 'Debes llenar este campo.'}]}>
              <Input />
            </Form.Item>

            <Form.Item wrapperCol={{offset: 8, span: 16}}>
              <Button onClick={this.handleCancelModal.bind(this)} style={{marginRight: 5}}>Cancelar</Button>
              <Button type="primary" htmlType="submit">Guardar</Button>
            </Form.Item>
          </Form>
        </Modal>


        {/* Modal for error */}
        <Modal
          open={this.state.modalError}
          title={'Motivo del error'}
          onOk={this.handleSubmitModalError.bind(this)}
          onCancel={this.handleCancelModalError.bind(this)}
          footer={false}
          width={'50%'}
        >
          <Form name="basic" labelCol={{span: 8}} wrapperCol={{span: 16}} initialValues={this.state.selectedRecord} onFinish={this.handleSubmitModalError.bind(this)} onFinishFailed={() => {}} autoComplete="off">
            <Form.Item label="Escribe el motivo del error" name="error" rules={[{required: true, message: 'Debes llenar este campo.'}]}>
              <Input />
            </Form.Item>

            <Form.Item wrapperCol={{offset: 8, span: 16}}>
              <Button onClick={this.handleCancelModalError.bind(this)} style={{marginRight: 5}}>Cancelar</Button>
              <Button type="primary" htmlType="submit">Guardar</Button>
            </Form.Item>
          </Form>
        </Modal>

        {/* Top menu */}
        <TopMenu />

        <Layout className="site-layout">
          {this.state.rol == 'Admin' ? 
            <SideMenu />
          : null}
          {this.state.rol == 'Profesor' ? 
            <SideMenuProfesor />
          : null}

          <Content style={{margin: '24px 16px 0', background: '#fff'}}>
            <div className="site-layout-background" style={{padding: 24, textAlign: 'center'}}>
              {this.state.rol === 'Admin' ? 
                <div style={{ margin: '16px 0', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexWrap: 'wrap' }}>
                  <div style={{marginBottom: 10}}>Ir a</div>
                  <div style={{marginLeft: 5, marginRight: 5, marginBottom: 10}}>
                    <a href="/organos-acreditadores" style={{background: '#1890ff', color: 'white', padding: '0px 5px', borderRadius: 5}}>Organos acreditadores</a>
                  </div>
                  <div style={{marginLeft: 5, marginRight: 5, marginBottom: 10}}>
                    <a href={`/organo-acreditador/${this.state.record.acreditor?.id}`} style={{background: '#1890ff', color: 'white', padding: '0px 5px', borderRadius: 5}}>Carreras de {this.state.record.acreditor?.shortName}</a>
                  </div>
                  <div style={{marginLeft: 5, marginRight: 5, marginBottom: 10}}>
                    <a href={`/acreditador/${this.state.acreditor.id}/carrera/${this.state.record.carrer?.id}/categorias`} style={{background: '#1890ff', color: 'white', padding: '0px 5px', borderRadius: 5}}>Categorías de {this.state.record.carrer?.name}</a>
                  </div>
                  <div style={{marginLeft: 5, marginRight: 5, marginBottom: 10}}>
                    <a href={`/acreditador/${this.state.acreditor.id}/carrera/${this.state.record.carrer?.id}/categorias/${this.state.record.criteriaCategory?.id}/criterios`} style={{background: '#1890ff', color: 'white', padding: '0px 5px', borderRadius: 5}}>Criterios de {this.state.record.criteriaCategory?.name}</a>
                  </div>
                  <div style={{marginLeft: 5, marginRight: 5, marginBottom: 10}}>
                    <a href={`/acreditador/${this.state.acreditor.id}/carrera/${this.state.record.carrer?.id}/categorias/${this.state.record.criteriaCategory?.id}/criterio/${this.state.record.criteria?.id}/indicadores`} style={{background: '#1890ff', color: 'white', padding: '0px 5px', borderRadius: 5}}>Indicadores de {this.state.record.criteria?.name}</a>
                  </div>
                </div>
              : 
                <div style={{ margin: '16px 0', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexWrap: 'wrap' }}>
                  <div style={{marginBottom: 10}}>Ir a</div>
                  <div style={{marginLeft: 5, marginRight: 5, marginBottom: 10}}>
                    <a href="/mis-indicadores" style={{background: '#1890ff', color: 'white', padding: '0px 5px', borderRadius: 5}}>Mis indicadores</a>
                  </div>
                </div>
              }

              <br />

              {/* Content */}
              <div className="container-between" style={{alignItems: 'flex-start'}}>
                <div style={{width: '70%', padding: 20}}>
                  {/* Información */}
                  <Card style={{ width: '100%', textAlign: 'left', border: `1px solid ${this.statusColor(this.state.record.status)}` }}>
                    <div className='container-between' style={{alignItems: 'flex-start', flexWrap: 'wrap', flexDirection: 'row'}}>
                      <div className="shadow ant-col-11" style={{padding: 10, marginBottom: 10}}>
                        <h4>Número de categoría: <b>{this.state.record.criteriaCategory?.folio}</b></h4>
                        <h4>Categoría: <b>{this.state.record.criteriaCategory?.name}</b></h4>
                      </div>
                      
                      <div className="shadow ant-col-11" style={{padding: 10, marginBottom: 10}}>
                        <h4>Número de criterio: <b>{this.state.record.criteria?.number}</b></h4>
                        <h4>Criterio: <b>{this.state.record.criteria?.name}</b></h4>
                      </div>
                    </div>
                    

                    <div style={{marginTop: 20, marginBottom: 20, paddingBottom: 20, borderBottom: `1px solid #f1f1f1`, paddingTop: 20, borderTop: `1px solid #f1f1f1`, display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                      <div>
                        {this.state.rol === 'Admin' || this.state.rol === 'Admin auxiliar' ? 
                          <Dropdown overlay={menu}>
                            <Button style={{backgroundColor: this.statusColor(this.state.record.status)}}>
                              <Space>{this.state.record.status} <DownOutlined /></Space>
                            </Button>
                          </Dropdown>
                        : 
                          <Button style={{backgroundColor: this.statusColor(this.state.record.status)}}>
                              <Space>{this.state.record.status}</Space>
                          </Button>
                        }

                        {this.state.record.status === 'Con errores' || this.state.record.status === 'Incompleto' ? 
                          <div style={{marginTop: 10, marginBottom: 5, color: '#FB4D4F'}}>Motivo: {this.state.record.reason}</div>
                        :null}
                      </div>

                      {this.state.rol === 'Admin' ? 
                        <a href={`/indicador-reporte/${this.state.record.id}`} target='_blank' style={{backgroundColor: "rgb(24, 144, 255)", color: "white", paddingTop: 5, paddingBottom: 5, paddingLeft: 10, paddingRight: 10, borderRadius: 5}}>
                          <Space>Modo Reporte</Space>
                        </a>
                      : null}
                    </div>
                    
                    <div className='container-between' style={{alignItems: 'flex-start', flexWrap: 'wrap', flexDirection: 'row'}}>
                      <div className="ant-col-11">
                        <h4 style={{marginBottom: 0}}>Número de indicador: <b>{this.state.record.folio}</b></h4>
                        <h4 style={{marginBottom: 0}}>Valor máximo: <b>{this.state.record.maxVal}</b></h4>

                        {this.state.record.acreditor?.shortName !== "CONAET" && this.state.rol == "Admin" ? 
                          <div style={{marginTop: 5, display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                            <input className='form-control' type='text' onChange={this.changeAdvancePorcent.bind(this)} defaultValue={this.state.record?.advancePorcent} style={{width: 50}} />
                            <h4 style={{marginBottom: 0, marginLeft: 5}}>Porciento de avance.</h4>
                          </div>
                        : null}
                        {this.state.record.acreditor?.shortName !== "CONAET" && this.state.rol != "Admin" ? 
                          <div style={{marginTop: 5}}>
                            <h4 style={{marginBottom: 0}}>{this.state.record?.advancePorcent}% de avance.</h4>
                          </div>
                        : null}
                      </div>

                      <div className="ant-col-11">
                        <h4 style={{marginBottom: 0}}>Carrera: <b>{this.state.record.carrer?.name}</b></h4>
                        <h4 style={{marginBottom: 0}}>Órgano acreditador: <b>{this.state.record.acreditor?.shortName}</b></h4>
                      </div>
                    </div>
                    
                    <br></br>
                    <br></br>
                    
                    <h4 style={{textAlign: 'left', borderTop: '1px solid rgb(241, 241, 241)', paddingTop: 15}}><strong>{this.state.record.question}</strong></h4>
                    <div style={{color: '#777'}} dangerouslySetInnerHTML={{ __html: this.state.record?.notes ? this.state.record.notes: 'Notas adicionales....' }} />
                  </Card>



                  {/* Llenado de datos */}
                  <Card title={this.state.record.acreditor?.shortName !== "CONAET" ? "Acciones realizadas" : "Fundamentación"} style={{ width: '100%', textAlign: 'left', marginTop: 20 }}>
                    <div style={{backgroundColor: '#F9FCFF', border: '1px solid #E8F4FF', padding: 15, borderRadius: 5}}>
                      <div className="ant-form-item" style={{marginBottom: 50}}>
                        <div className="ant-row ant-form-item-row">

                          <div className="ant-col ant-col-24 ant-form-item-label">
                            <div style={{backgroundColor: '#FFF0B9', padding: 5, borderRadius: 5, marginBottom: 10}}>
                              <p style={{marginBottom: 0}}>1. Responde a cada inciso descrito arriba.</p>
                              <p style={{marginBottom: 0}}>2. Busca la evidencia desde tu computadora.</p>
                              <p style={{marginBottom: 0}}>3. El sistema agregará la evidencia automáticamente al final de tu respuesta. </p>
                            </div>
                          </div>

                          <div className="ant-col ant-col-24 ant-form-item-control">
                            <ReactQuill modules={Configuration.editorModules} formats={Configuration.editorFormats} theme="snow" value={this.state.fundationValue} onChange={(val) => {this.setState({fundationValue: val})}} style={{height: 200}} />
                          </div>
                        </div>

                        <Button type='primary' icon={<UploadOutlined />} style={{marginTop: 50, width: '100%'}} onClick={() => this.sendResponse()}>
                          Guardar {this.state.record.acreditor?.shortName !== "CONAET" ? "acción" : "fundamentación"}
                        </Button>
                      </div>

                      <div className="ant-form-item" style={{marginBottom: 100}}>
                        <br />
                        <label className="ant-form-item" title="Registro oficial"><b>Sube tu evidencia</b></label>
                        <div style={{backgroundColor: '#FFF0B9', padding: 5, borderRadius: 5, marginBottom: 10}}>
                          <p style={{marginBottom: 0}}>1. Selecciona tu evidencia desde tu computadora.</p>
                          <p style={{marginBottom: 0}}>2. Confirma y sube tus archivos.</p>
                          <p style={{marginBottom: 0}}>3. El sistema agregará la evidencia automáticamente al final de tu respuesta.</p>
                        </div>

                        <div className="ant-row ant-form-item-row">
                          <div className="ant-col ant-col-24" style={{paddingBottom: 10}}>
                            <div>
                              <Upload onRemove={(file) => { this.removeUpload(file) }} beforeUpload={(file) => { this.beforeUpload(file) }} fileList={this.state.evidencesTmp}>
                                <Button type='primary' icon={<UploadOutlined />} block>Adjuntar nueva evidencia</Button>
                              </Upload>

                              {this.state.evidencesTmp.length > 0 ?
                                <Button icon={<UploadOutlined />} style={{backgroundColor: '#62DEA1', color: '#212121', marginTop: 20, width: '100%'}} onClick={() => this.handleUpload()}>Confirma y sube la evidencia</Button>
                              : null}
                            </div>
                          </div>
                          
                          {/* Uploaded files */}
                          {this.state.evidences && this.state.evidences.length > 0 ? 
                            <div className="ant-col ant-col-25 ant-form-item-control">
                              <br/>
                              <label className="ant-form-item" title="Registro oficial" style={{marginBottom: 0}}><b>Evidencia que haz subido</b></label>
                              <div style={{backgroundColor: '#FFF0B9', padding: 5, borderRadius: 5, marginBottom: 10}}>
                                <p style={{marginBottom: 0}}>1. Elimina la evidencia que haz agregado.</p>
                                <p style={{marginBottom: 0}}>2. El sistema borrará la evidencia automáticamente al final de tu respuesta.</p>
                              </div>

                              <List
                                className="demo-loadmore-list"
                                loading={false}
                                itemLayout="horizontal"
                                dataSource={this.state.evidences}
                                style={{width: '100%', paddingLeft: 5}}
                                renderItem={(item, index) => (
                                  <List.Item
                                    actions={[
                                      <Button key={`file-edit-${item.id}`}>
                                        <a href={`${Configuration.apiServer}${item.file}`} target="_blank" rel="noreferrer">Ver</a>
                                      </Button>,
                                      <Button key={`file-delete-${item.id}`} danger>
                                        <Popconfirm title="¿Deseas borrar el registro?" cancelText='Cancelar' onConfirm={this.deleteEvidence.bind(this, item)}>Borrar</Popconfirm>
                                      </Button>
                                    ]}
                                  >
                                    {index+1}. <Skeleton title={false} loading={false}><FileOutlined /></Skeleton> {item.description}
                                  </List.Item>
                                )}
                              />
                            </div>
                          : null}

                        </div>
                      </div>
                    </div>
                  </Card>



                  {/* Calculo de porcentaje alcanzado */}
                  {this.state.record.acreditor?.shortName !== "CONAET" ?
                    <Card title="Cálculo de porcentaje alcanzado" style={{ width: '100%', textAlign: 'left', marginTop: 20 }}>
                      <div style={{backgroundColor: '#F9FCFF', border: '1px solid #E8F4FF', padding: 15, borderRadius: 5}}>
                        <div className="ant-form-item" style={{marginBottom: 50}}>
                          <div className="ant-row ant-form-item-row">
                            <textarea className='form-control' style={{height: 150}} onChange={(e) => { this.setState({inputCalculusAdvance: e.target.value}) }} defaultValue={this.state.record?.calculusAdvance}></textarea>

                            <Button style={{backgroundColor: '#1677ff', color: '#fff', marginTop: 10, width: '100%'}} onClick={() => this.uploadCalculusAdvance()}>Guardar</Button>
                          </div>
                        </div>
                      </div>
                    </Card>
                  : null}


                  
                  <Card title="Comentarios" style={{ width: '100%', textAlign: 'left', marginTop: 20 }}>
                    <h4 style={{textAlign: 'left'}}>Total de comentarios <Tag color="green">{this.state.comments.length}</Tag></h4>

                    <div style={{backgroundColor: '#f1f1f1', padding: '20px 15px 10px 15px'}}>
                      <Form name="commentForm" labelCol={{span: 8}} wrapperCol={{span: 16}} onFinish={this.createComment.bind(this)} onFinishFailed={() => {}} autoComplete="off" className='container-between'>
                        <Form.Item name="comment" rules={[{required: true, message: 'Debes llenar este campo.'}]} style={{width: '100%'}}>
                          <Input placeholder="Escribe tu comentario..." />
                        </Form.Item>

                        <Form.Item>
                          <Button type="primary" htmlType='submit'>Comentar</Button>
                        </Form.Item>
                      </Form>
                    </div>

                    <List
                      className="comment-list"
                      itemLayout="horizontal"
                      dataSource={this.state.comments}
                      style={{textAlign: 'left', background: '#fff', padding: 20}}
                      renderItem={item => (
                        <li key={`comment-${item.id}`}>
                          {/* <Comment
                            actions={
                              this.state.currentUser === item.user.id ?
                                [
                                  <Tooltip title="Borrar" onClick={() => {this.deleteComment(item.id)}}>
                                    <div style={{border: '1px solid #ff4d4f', borderRadius: 5, padding: '3px 5px'}}>
                                      <DeleteOutlined style={{color: '#ff4d4f', fontSize: 12}} />
                                      <span style={{color: '#ff4d4f', marginLeft: 5, fontSize: 12}}>Borrar</span>
                                    </div>
                                  </Tooltip>
                                ]
                              : 
                                []
                            }
                            author={
                              <h3>{item.user?.name}</h3>
                            }
                            datetime={item.insertedAt}
                            content={
                              <p style={{borderTop: '1px dotted #ddd', paddingTop: 10, fontSize: 15}}>
                                {item.description}
                              </p>
                            }
                          /> */}
                          <div style={{borderTop: '1px dotted #ddd', paddingTop: 10, paddingBottom: 10}}>
                            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                              <div>
                                <h3>{item.user?.name}</h3>
                                <h5>{item.insertedAt}</h5>
                              </div>

                              <Tooltip title="Borrar" onClick={() => {this.deleteComment(item.id)}}>
                                <div style={{border: '1px solid #ff4d4f', borderRadius: 5, padding: '3px 5px'}}>
                                  <DeleteOutlined style={{color: '#ff4d4f', fontSize: 12}} />
                                  <span style={{color: '#ff4d4f', marginLeft: 5, fontSize: 12}}>Borrar</span>
                                </div>
                              </Tooltip>
                            </div>

                            <p style={{paddingTop: 10, fontSize: 15}}>
                              {item.description}
                            </p>
                          </div>
                        </li>
                      )}
                    />
                  </Card>
                </div>
                
                <div className='shadow' style={{width: '28%', background: '#fff', padding: 30, marginTop: 20}}>
                  <Timeline style={{maxHeight: '50vh', overflowY: 'scroll'}} items={
                    this.state.progress.map((item) => {
                      return {children: <div><b>{item.title}</b> <p style={{marginBottom: 5}}><small>{item.insertedAt}</small></p></div>}
                    })
                  } />
                  {/* {
                    this.state.progress.map((item) => {
                      return (
                        <Timeline.Item style={{textAlign: 'left'}} key={`prog-${item.id}`}><b>{item.title}</b> <p style={{marginBottom: 5}}><small>{item.insertedAt}</small></p></Timeline.Item>
                      )
                    })
                  } */}
                </div>
              </div>

            </div>
          </Content>


        </Layout>
      </Layout>
    );
  }
}

export default withParams(IndicadorDetalles);
