import { FileOutlined } from '@ant-design/icons';
import { Layout, Menu, Card, Button, Tooltip, Space, Alert, message, List, Skeleton } from 'antd';
import React, { Component } from 'react';
import axios from 'axios';
import Configuration from '../Configuration.js';
import { Navigate, useParams } from 'react-router-dom';

const { Content } = Layout;

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

class RequerimientosPreviosReporte extends Component {
  constructor(props) {
    super(props);

    const comments = [];
    const progress = [];

    // Auth
    const jwt = localStorage.getItem('token');
    this.headers = {authorization: `Bearer: ${jwt}`};

    // Set state
    this.state = {
      currentUser: 0,
      rol: '',
      acreditor: {},
      conaetOfficialRecordInstitutional: '',
      conaetOfficialRecordInstitutionalResponse: '',
      conaetEducativeModel: '',
      conaetEducativeModelResponse: '',
      conaetAgeEducationalProgram: '',
      conaetAgeEducationalProgramResponse: '',
      conaetRelevanceStudy: '',
      conaetRelevanceStudyResponse: '',
      conaetGraduateGenerations: '',
      conaetGraduateGenerationsResponse: '',
      conaetStatisticsData: '',
      conaetStatisticsDataResponse: '',
      conaetOfficialRecordInstitutionalTmp: [],
      conaetOfficialRecordInstitutionalFiles: [],
      conaetEducativeModelTmp: [],
      conaetEducativeModelFiles: [],
      conaetAgeEducationalProgramTmp: [],
      conaetAgeEducationalProgramFiles: [],
      conaetRelevanceStudyTmp: [],
      conaetRelevanceStudyFiles: [],
      conaetGraduateGenerationsTmp: [],
      conaetGraduateGenerationsFiles: [],
      conaetStatisticsDataTmp: [],
      conaetStatisticsDataFiles: [],
    };
  }

  componentDidMount() {
    const params = this.props.params;
    if ('id' in params) {
      const rol =  localStorage.getItem('rol');
      const id =  localStorage.getItem('userid');

      this.setState({rol: rol, currentUser: id});
      this.getAcreditor(params.id);
    }
  }

  getAcreditor(id) {
    const query = `{getAcreditor(id: ${id}){id,shortName,conaetOfficialRecordInstitutional,conaetOfficialRecordInstitutionalResponse,conaetEducativeModel,conaetEducativeModelResponse,conaetAgeEducationalProgram,conaetAgeEducationalProgramResponse,conaetRelevanceStudy,conaetRelevanceStudyResponse,conaetGraduateGenerations,conaetGraduateGenerationsResponse,conaetStatisticsData,conaetStatisticsDataResponse}}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al procesar tus credenciales');
      }
      
      if('errors' in resp.data) {
        console.log(resp.data);
        return message.warning('Error al consultar el órgano acreditador.');
      }

      this.setState({
        acreditor: resp.data.data.getAcreditor, 
        conaetOfficialRecordInstitutional: resp.data.data.getAcreditor?.conaetOfficialRecordInstitutional, 
        conaetOfficialRecordInstitutionalResponse: resp.data.data.getAcreditor?.conaetOfficialRecordInstitutionalResponse, 
        conaetEducativeModel: resp.data.data.getAcreditor?.conaetEducativeModel, 
        conaetEducativeModelResponse: resp.data.data.getAcreditor?.conaetEducativeModelResponse, 
        conaetAgeEducationalProgram: resp.data.data.getAcreditor?.conaetAgeEducationalProgram, 
        conaetAgeEducationalProgramResponse: resp.data.data.getAcreditor?.conaetAgeEducationalProgramResponse, 
        conaetRelevanceStudy: resp.data.data.getAcreditor?.conaetRelevanceStudy, 
        conaetRelevanceStudyResponse: resp.data.data.getAcreditor?.conaetRelevanceStudyResponse, 
        conaetGraduateGenerations: resp.data.data.getAcreditor?.conaetGraduateGenerations, 
        conaetGraduateGenerationsResponse: resp.data.data.getAcreditor?.conaetGraduateGenerationsResponse, 
        conaetStatisticsData: resp.data.data.getAcreditor?.conaetStatisticsData,
        conaetStatisticsDataResponse: resp.data.data.getAcreditor?.conaetStatisticsDataResponse
      });
      // Get evidence
      this.getEvidence(id, 'conaetOfficialRecordInstitutional');
      this.getEvidence(id, 'conaetEducativeModel');
      this.getEvidence(id, 'conaetAgeEducationalProgram');
      this.getEvidence(id, 'conaetRelevanceStudy');
      this.getEvidence(id, 'conaetGraduateGenerations');
      this.getEvidence(id, 'conaetStatisticsData');
    });
  }

  getEvidence(id, kind) {
      const query = `{listAcreditorEvidences(acreditorId: ${id}, kind: "${kind}"){id,photo,kind,name}}`;
  
      axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
        if(resp.status !== 200) {
          return message.warning('Hubo un error al procesar tus credenciales');
        }
        
        if('errors' in resp.data) {
          console.log(resp.data);
          return message.warning('Error al obtener la evidencia.');
        }
  
        const response = resp.data.data.listAcreditorEvidences;
        // OfficialRecordInstitutional
        if (kind === 'conaetOfficialRecordInstitutional') {
          // Update state
          this.setState({conaetOfficialRecordInstitutionalFiles: response});
        }
        // ConaetEducativeModel
        if (kind === 'conaetEducativeModel') {
          this.setState({conaetEducativeModelFiles: response});
        }
        // conaetAgeEducationalProgram
        if (kind === 'conaetAgeEducationalProgram') {
          this.setState({conaetAgeEducationalProgramFiles: response});
        }
        // conaetRelevanceStudy
        if (kind === 'conaetRelevanceStudy') {
          this.setState({conaetRelevanceStudyFiles: response});
        }
        // conaetGraduateGenerations
        if (kind === 'conaetGraduateGenerations') {
          this.setState({conaetGraduateGenerationsFiles: response});
        }
        // conaetStatisticsData
        if (kind === 'conaetStatisticsData') {
          this.setState({conaetStatisticsDataFiles: response});
        }
      });
    }

  // Render UI
  render() {
    if(this.state.redirect) {
      return (
        <Navigate to={this.state.redirectUrl} />
      );
    }

    return (
      <Layout>
        <Layout>

          <Content style={{margin: '24px 16px 0', background: '#fff'}}>
            <div className="site-layout-background" style={{padding: 24, textAlign: 'center'}}>

              {/* Content */}
              <div className="container-between" style={{alignItems: 'flex-start'}}>
                <div style={{width: '100%', padding: 20}}>
                  {/* Información */}
                  <Card style={{ width: '100%'}}>
                    <div style={{width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                      <a href="javascript: window.print();" style={{borderRadius: 5, borderColor: '#ccc', borderWidth: 1, borderStyle: 'solid', padding: 3, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="1024" height="1024" viewBox="0 0 1024 1024" style={{color: '#ccc', height: 20, width: 25}}><path fill="currentColor" d="M820 436h-40c-4.4 0-8 3.6-8 8v40c0 4.4 3.6 8 8 8h40c4.4 0 8-3.6 8-8v-40c0-4.4-3.6-8-8-8m32-104H732V120c0-4.4-3.6-8-8-8H300c-4.4 0-8 3.6-8 8v212H172c-44.2 0-80 35.8-80 80v328c0 17.7 14.3 32 32 32h168v132c0 4.4 3.6 8 8 8h424c4.4 0 8-3.6 8-8V772h168c17.7 0 32-14.3 32-32V412c0-44.2-35.8-80-80-80M360 180h304v152H360zm304 664H360V568h304zm200-140H732V500H292v204H160V412c0-6.6 5.4-12 12-12h680c6.6 0 12 5.4 12 12z"/></svg>
                      </a>

                      <h4>Órgano Acreditador: <b>{this.state.acreditor.shortName}</b></h4>
                    </div>
                  </Card>



                  {/* Registro oficial */}
                  <Card title="Registro oficial" style={{ width: '100%', textAlign: 'left', marginTop: 20 }}>

                    <div style={{backgroundColor: '#F9FCFF', border: '1px solid #E8F4FF', padding: 15, borderRadius: 5}}>
                      <h3>Requisitos</h3>
                      <div className="ant-form-item" style={{marginBottom: 50}}>
                        <div className="ant-row ant-form-item-row">
                          <div className="ant-col ant-col-24 ant-form-item-control">
                            <div dangerouslySetInnerHTML={{ __html: this.state.conaetOfficialRecordInstitutional.replace('RESPUESTA DEL PROGRAMA EDUCATIVO A CADA INCISO:', '').replace('EVIDENCIA(S) DEL PROGRAMA EDUCATIVO:', '').replace('ANEXAR LOS DOCUMENTOS PROBATORIOS', '') }} />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div style={{backgroundColor: '#F9FCFF', border: '1px solid #E8F4FF', padding: 15, borderRadius: 5}}>
                      <h3>Respuesta</h3>
                      <div className="ant-form-item" style={{marginBottom: 50}}>
                        <div className="ant-row ant-form-item-row">
                          <div className="ant-col ant-col-24 ant-form-item-control">
                            <div dangerouslySetInnerHTML={{ __html: this.state.conaetOfficialRecordInstitutionalResponse }} />
                          </div>
                        </div>

                        <br />

                        {/* Uploaded files */}
                        {this.state.conaetOfficialRecordInstitutionalFiles && this.state.conaetOfficialRecordInstitutionalFiles.length > 0 ? 
                          <div className="ant-col ant-col-25 ant-form-item-control">
                            <br/>
                            <label className="ant-form-item" title="Registro oficial" style={{marginBottom: 0}}><b>Evidencia</b></label>
    
                            <List
                              className="demo-loadmore-list"
                              loading={false}
                              itemLayout="horizontal"
                              dataSource={this.state.conaetOfficialRecordInstitutionalFiles}
                              style={{width: '100%', paddingLeft: 5}}
                              renderItem={(item, index) => (
                                <List.Item actions={[]}>
                                  {index+1}. <Skeleton title={false} loading={false}><FileOutlined /></Skeleton> {item.name}
                                  <p><a href={`${Configuration.apiServer}${item.photo}`} style={{fontSize: '0.7rem'}}>{Configuration.apiServer}{item.photo}</a></p>
                                </List.Item>
                              )}
                            />
                          </div>
                        : null}
                      </div>
                    </div>

                  </Card>



                  {/* Modelo educativo */}
                  <Card title="Modelo educativo" style={{ width: '100%', textAlign: 'left', marginTop: 20 }}>

                    <div style={{backgroundColor: '#F9FCFF', border: '1px solid #E8F4FF', padding: 15, borderRadius: 5}}>
                      <h3>Requisitos</h3>
                      <div className="ant-form-item" style={{marginBottom: 50}}>
                        <div className="ant-row ant-form-item-row">
                          <div className="ant-col ant-col-24 ant-form-item-control">
                            <div dangerouslySetInnerHTML={{ __html: this.state.conaetEducativeModel.replace('RESPUESTA DEL PROGRAMA EDUCATIVO A CADA INCISO:', '').replace('EVIDENCIA(S) DEL PROGRAMA EDUCATIVO:', '').replace('ANEXAR LOS DOCUMENTOS PROBATORIOS', '') }} />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div style={{backgroundColor: '#F9FCFF', border: '1px solid #E8F4FF', padding: 15, borderRadius: 5}}>
                      <h3>Respuesta</h3>
                      <div className="ant-form-item" style={{marginBottom: 50}}>
                        <div className="ant-row ant-form-item-row">
                          <div className="ant-col ant-col-24 ant-form-item-control">
                            <div dangerouslySetInnerHTML={{ __html: this.state.conaetEducativeModelResponse }} />
                          </div>
                        </div>

                        <br />

                        {/* Uploaded files */}
                        {this.state.conaetEducativeModelFiles && this.state.conaetEducativeModelFiles.length > 0 ? 
                          <div className="ant-col ant-col-25 ant-form-item-control">
                            <br/>
                            <label className="ant-form-item" title="Registro oficial" style={{marginBottom: 0}}><b>Evidencia</b></label>
    
                            <List
                              className="demo-loadmore-list"
                              loading={false}
                              itemLayout="horizontal"
                              dataSource={this.state.conaetEducativeModelFiles}
                              style={{width: '100%', paddingLeft: 5}}
                              renderItem={(item, index) => (
                                <List.Item actions={[]}>
                                  {index+1}. <Skeleton title={false} loading={false}><FileOutlined /></Skeleton> {item.name}
                                  <p><a href={`${Configuration.apiServer}${item.photo}`} style={{fontSize: '0.7rem'}}>{Configuration.apiServer}{item.photo}</a></p>
                                </List.Item>
                              )}
                            />
                          </div>
                        : null}
                      </div>
                    </div>

                  </Card>



                  {/* Antigüedad del programa educativo */}
                  <Card title="Antigüedad del programa educativo" style={{ width: '100%', textAlign: 'left', marginTop: 20 }}>

                    <div style={{backgroundColor: '#F9FCFF', border: '1px solid #E8F4FF', padding: 15, borderRadius: 5}}>
                      <h3>Requisitos</h3>
                      <div className="ant-form-item" style={{marginBottom: 50}}>
                        <div className="ant-row ant-form-item-row">
                          <div className="ant-col ant-col-24 ant-form-item-control">
                            <div dangerouslySetInnerHTML={{ __html: this.state.conaetAgeEducationalProgram.replace('RESPUESTA DEL PROGRAMA EDUCATIVO A CADA INCISO:', '').replace('EVIDENCIA(S) DEL PROGRAMA EDUCATIVO:', '').replace('ANEXAR LOS DOCUMENTOS PROBATORIOS', '') }} />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div style={{backgroundColor: '#F9FCFF', border: '1px solid #E8F4FF', padding: 15, borderRadius: 5}}>
                      <h3>Respuesta</h3>
                      <div className="ant-form-item" style={{marginBottom: 50}}>
                        <div className="ant-row ant-form-item-row">
                          <div className="ant-col ant-col-24 ant-form-item-control">
                            <div dangerouslySetInnerHTML={{ __html: this.state.conaetAgeEducationalProgramResponse }} />
                          </div>
                        </div>

                        <br />

                        {/* Uploaded files */}
                        {this.state.conaetAgeEducationalProgramFiles && this.state.conaetAgeEducationalProgramFiles.length > 0 ? 
                          <div className="ant-col ant-col-25 ant-form-item-control">
                            <br/>
                            <label className="ant-form-item" title="Registro oficial" style={{marginBottom: 0}}><b>Evidencia</b></label>
    
                            <List
                              className="demo-loadmore-list"
                              loading={false}
                              itemLayout="horizontal"
                              dataSource={this.state.conaetAgeEducationalProgramFiles}
                              style={{width: '100%', paddingLeft: 5}}
                              renderItem={(item, index) => (
                                <List.Item actions={[]}>
                                  {index+1}. <Skeleton title={false} loading={false}><FileOutlined /></Skeleton> {item.name}
                                  <p><a href={`${Configuration.apiServer}${item.photo}`} style={{fontSize: '0.7rem'}}>{Configuration.apiServer}{item.photo}</a></p>
                                </List.Item>
                              )}
                            />
                          </div>
                        : null}
                      </div>
                    </div>

                  </Card>



                  {/* Estudio de pertinencia del programa educativo */}
                  <Card title="Estudio de pertinencia del programa educativo" style={{ width: '100%', textAlign: 'left', marginTop: 20 }}>

                    <div style={{backgroundColor: '#F9FCFF', border: '1px solid #E8F4FF', padding: 15, borderRadius: 5}}>
                      <h3>Requisitos</h3>
                      <div className="ant-form-item" style={{marginBottom: 50}}>
                        <div className="ant-row ant-form-item-row">
                          <div className="ant-col ant-col-24 ant-form-item-control">
                            <div dangerouslySetInnerHTML={{ __html: this.state.conaetRelevanceStudy.replace('RESPUESTA DEL PROGRAMA EDUCATIVO A CADA INCISO:', '').replace('EVIDENCIA(S) DEL PROGRAMA EDUCATIVO:', '').replace('ANEXAR LOS DOCUMENTOS PROBATORIOS', '') }} />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div style={{backgroundColor: '#F9FCFF', border: '1px solid #E8F4FF', padding: 15, borderRadius: 5}}>
                      <h3>Respuesta</h3>
                      <div className="ant-form-item" style={{marginBottom: 50}}>
                        <div className="ant-row ant-form-item-row">
                          <div className="ant-col ant-col-24 ant-form-item-control">
                            <div dangerouslySetInnerHTML={{ __html: this.state.conaetRelevanceStudyResponse }} />
                          </div>
                        </div>

                        <br />

                        {/* Uploaded files */}
                        {this.state.conaetRelevanceStudyFiles && this.state.conaetRelevanceStudyFiles.length > 0 ? 
                          <div className="ant-col ant-col-25 ant-form-item-control">
                            <br/>
                            <label className="ant-form-item" title="Registro oficial" style={{marginBottom: 0}}><b>Evidencia</b></label>
    
                            <List
                              className="demo-loadmore-list"
                              loading={false}
                              itemLayout="horizontal"
                              dataSource={this.state.conaetRelevanceStudyFiles}
                              style={{width: '100%', paddingLeft: 5}}
                              renderItem={(item, index) => (
                                <List.Item actions={[]}>
                                  {index+1}. <Skeleton title={false} loading={false}><FileOutlined /></Skeleton> {item.name}
                                  <p><a href={`${Configuration.apiServer}${item.photo}`} style={{fontSize: '0.7rem'}}>{Configuration.apiServer}{item.photo}</a></p>
                                </List.Item>
                              )}
                            />
                          </div>
                        : null}
                      </div>
                    </div>

                  </Card>



                  {/* Generaciones de egreso del programa educativo */}
                  <Card title="Generaciones de egreso del programa educativo" style={{ width: '100%', textAlign: 'left', marginTop: 20 }}>

                    <div style={{backgroundColor: '#F9FCFF', border: '1px solid #E8F4FF', padding: 15, borderRadius: 5}}>
                      <h3>Requisitos</h3>
                      <div className="ant-form-item" style={{marginBottom: 50}}>
                        <div className="ant-row ant-form-item-row">
                          <div className="ant-col ant-col-24 ant-form-item-control">
                            <div dangerouslySetInnerHTML={{ __html: this.state.conaetGraduateGenerations.replace('RESPUESTA DEL PROGRAMA EDUCATIVO A CADA INCISO:', '').replace('EVIDENCIA(S) DEL PROGRAMA EDUCATIVO:', '').replace('ANEXAR LOS DOCUMENTOS PROBATORIOS', '') }} />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div style={{backgroundColor: '#F9FCFF', border: '1px solid #E8F4FF', padding: 15, borderRadius: 5}}>
                      <h3>Respuesta</h3>
                      <div className="ant-form-item" style={{marginBottom: 50}}>
                        <div className="ant-row ant-form-item-row">
                          <div className="ant-col ant-col-24 ant-form-item-control">
                            <div dangerouslySetInnerHTML={{ __html: this.state.conaetGraduateGenerationsResponse }} />
                          </div>
                        </div>

                        <br />

                        {/* Uploaded files */}
                        {this.state.conaetGraduateGenerationsFiles && this.state.conaetGraduateGenerationsFiles.length > 0 ? 
                          <div className="ant-col ant-col-25 ant-form-item-control">
                            <br/>
                            <label className="ant-form-item" title="Registro oficial" style={{marginBottom: 0}}><b>Evidencia</b></label>
    
                            <List
                              className="demo-loadmore-list"
                              loading={false}
                              itemLayout="horizontal"
                              dataSource={this.state.conaetGraduateGenerationsFiles}
                              style={{width: '100%', paddingLeft: 5}}
                              renderItem={(item, index) => (
                                <List.Item actions={[]}>
                                  {index+1}. <Skeleton title={false} loading={false}><FileOutlined /></Skeleton> {item.name}
                                  <p><a href={`${Configuration.apiServer}${item.photo}`} style={{fontSize: '0.7rem'}}>{Configuration.apiServer}{item.photo}</a></p>
                                </List.Item>
                              )}
                            />
                          </div>
                        : null}
                      </div>
                    </div>

                  </Card>



                  {/* Datos estadísticos del programa educativo */}
                  <Card title="Datos estadísticos del programa educativo" style={{ width: '100%', textAlign: 'left', marginTop: 20 }}>

                    <div style={{backgroundColor: '#F9FCFF', border: '1px solid #E8F4FF', padding: 15, borderRadius: 5}}>
                      <h3>Requisitos</h3>
                      <div className="ant-form-item" style={{marginBottom: 50}}>
                        <div className="ant-row ant-form-item-row">
                          <div className="ant-col ant-col-24 ant-form-item-control">
                            <div dangerouslySetInnerHTML={{ __html: this.state.conaetStatisticsData.replace('RESPUESTA DEL PROGRAMA EDUCATIVO A CADA INCISO:', '').replace('EVIDENCIA(S) DEL PROGRAMA EDUCATIVO:', '').replace('ANEXAR LOS DOCUMENTOS PROBATORIOS', '') }} />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div style={{backgroundColor: '#F9FCFF', border: '1px solid #E8F4FF', padding: 15, borderRadius: 5}}>
                      <h3>Respuesta</h3>
                      <div className="ant-form-item" style={{marginBottom: 50}}>
                        <div className="ant-row ant-form-item-row">
                          <div className="ant-col ant-col-24 ant-form-item-control">
                            <div dangerouslySetInnerHTML={{ __html: this.state.conaetStatisticsDataResponse }} />
                          </div>
                        </div>

                        <br />

                        {/* Uploaded files */}
                        {this.state.conaetStatisticsDataFiles && this.state.conaetStatisticsDataFiles.length > 0 ? 
                          <div className="ant-col ant-col-25 ant-form-item-control">
                            <br/>
                            <label className="ant-form-item" title="Registro oficial" style={{marginBottom: 0}}><b>Evidencia</b></label>
    
                            <List
                              className="demo-loadmore-list"
                              loading={false}
                              itemLayout="horizontal"
                              dataSource={this.state.conaetStatisticsDataFiles}
                              style={{width: '100%', paddingLeft: 5}}
                              renderItem={(item, index) => (
                                <List.Item actions={[]}>
                                  {index+1}. <Skeleton title={false} loading={false}><FileOutlined /></Skeleton> {item.name}
                                  <p><a href={`${Configuration.apiServer}${item.photo}`} style={{fontSize: '0.7rem'}}>{Configuration.apiServer}{item.photo}</a></p>
                                </List.Item>
                              )}
                            />
                          </div>
                        : null}
                      </div>
                    </div>

                  </Card>

                </div>
              </div>

            </div>
          </Content>


        </Layout>
      </Layout>
    );
  }
}

export default withParams(RequerimientosPreviosReporte);
